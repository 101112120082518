import { SET_ALL_USERS, SET_CURRENT_USER } from './action-types';

export const setCurrentUser = (user) => ({
  type: SET_CURRENT_USER,
  payload: {
    user,
  },
});

export const setAllUsers = (users) => ({
  type: SET_ALL_USERS,
  payload: {
    users,
  },
});
