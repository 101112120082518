import { SAVE_TOKEN, CLEAR_TOKEN } from '../actions/action-types';

const tokenReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case SAVE_TOKEN:
      return {
        ...state,
        authToken: payload.token,
      };

    case CLEAR_TOKEN:
      return {
        ...state,
        authToken: '',
      };

    default:
      return state;
  }
};

export default tokenReducer;
