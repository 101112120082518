import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { BACKEND_URL } from './utils/config';
import { setGraphQLErrors, setNetworkError } from './actions/error-actions';
import { logout } from './utils/logout';
import store from './store/store';

console.log(`Backend url: ${BACKEND_URL}`);

/*
const isApolloAuthenticated = () => {
  const redux = localStorage.getItem('redux');
  let token = (redux && JSON.parse(redux).token) || {};
  token = token.authToken;

  return token && token.length > 0;
};
*/

const link = new HttpLink({ uri: `${BACKEND_URL}/graphql` });
const authLink = setContext((_, { headers }) => {
  const redux = localStorage.getItem('redux');
  let token = (redux && JSON.parse(redux).token) || {};
  token = token.authToken;

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : null,
    },
  };
});

const errorsLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    store.dispatch(setGraphQLErrors(graphQLErrors, operation));

    if (
      graphQLErrors.length > 0 &&
      graphQLErrors[0].extensions &&
      'code' in graphQLErrors[0].extensions &&
      graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
    ) {
      logout();
    }
  }

  if (networkError && 'statusCode' in networkError) {
    if (networkError.statusCode === 401) {
      logout();
    } else {
      store.dispatch(setNetworkError(networkError, operation));
    }
  }
});

const client = new ApolloClient({
  link: from([authLink, errorsLink, link]),
  cache: new InMemoryCache(),
});

export default client;
