const priorityColor = (id: string, prefix = 'bg-') => {
  switch (id) {
    case 'priority_l':
      return [`${prefix}success`, 'text-white'];
    case 'priority_m':
      return [`${prefix}custom-yellow`, 'text-secondary'];
    case 'priority_h':
      return [`${prefix}warning`, 'text-white'];
    case 'priority_vh':
      return [`${prefix}danger`, 'text-white'];
    default:
      return ['', ''];
  }
};

export default priorityColor;
