import React from 'react';
import { Card } from 'react-bootstrap';

import SignIn from '../../components/auth/SignIn';

const SignInPage = () => (
  <React.Fragment>
    <div className="text-center mt-4">
      <p className="lead">Sign in to your account to continue</p>
    </div>

    <Card>
      <Card.Body>
        <div className="m-sm-4">
          <SignIn />
        </div>
      </Card.Body>
    </Card>
  </React.Fragment>
);

export default SignInPage;
