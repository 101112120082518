import React, { Component, useContext } from 'react';
import { DialogContent, DialogTitle } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import RiskGroupsCreateMutation from '../../mutations/RiskGroupsCreateMutation';
import { useMutation } from '@apollo/client';
import NotyfContext from '../../contexts/NotyfContext';
import {
  displayErrorMessage,
  displaySuccessMessage,
} from '../../utils/displayMessage';

class AddDialog extends Component {
  handleAdd = async () => {
    const { addGroupMutHandler, handleAddDialogClose, notyf } = this.props;

    try {
      this.setState({ ...this.state, loading: true });
      let result = await addGroupMutHandler({
        variables: {
          input: {
            name: this.state.name,
            abbreviation: this.state.abbreviation,
            leaderId: this.state.leader.id,
            parentGroupId: parseInt(this.state.riskGroup.id),
          },
        },
      });

      const createResult = result.data.riskGroupsCreate;
      const errors = createResult.errors;

      if (
        result != null &&
        createResult != null &&
        createResult.errors != null &&
        createResult.errors.length > 0
      ) {
        displayErrorMessage(notyf, errors[0].message);
      } else {
        handleAddDialogClose(createResult.riskGroups);
        displaySuccessMessage(
          notyf,
          `Group ${this.state.name} has been created!`
        );
      }
    } catch (err) {
      displayErrorMessage(notyf, err.message);
    } finally {
      this.setState({ ...this.state, loading: false });
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      name: null,
      abbreviation: null,
      leader: null,
      parentGroup: null,
      number: null,
      default_range: null,
      default_globality: null,
      loading: false,
    };
  }

  render() {
    const { open, handleAddDialogClose, groups, users } = this.props;

    return (
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleAddDialogClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          Create new risk group
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl style={{ width: 300 }}>
                <TextField
                  id="standard-multiline-flexible"
                  label="Name"
                  multiline
                  onChange={(event) => {
                    this.setState({ ...this.state, name: event.target.value });
                  }}
                  style={{ width: 500 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl style={{ width: 300 }}>
                <TextField
                  id="standard-multiline-flexible"
                  label="Abbreviation"
                  multiline
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      abbreviation: event.target.value,
                    });
                  }}
                  style={{ width: 500 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl style={{ width: 300 }}>
                <InputLabel id="demo-simple-select-label">
                  Group Leader
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      leader: users.find((u) => u.name === event.target.value),
                    });
                  }}
                >
                  {users &&
                    users.map((user) => (
                      <MenuItem value={user.name}>{user.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <FormControl style={{ width: 300 }}>
                <InputLabel id="demo-simple-select-label">
                  Parent group
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(event) =>
                    this.setState({
                      ...this.state,
                      riskGroup: groups.find(
                        (g) => g.name === event.target.value
                      ),
                    })
                  }
                >
                  {groups &&
                    groups.map((group) => (
                      <MenuItem key={group.id} value={group.name}>
                        {group.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={this.state.loading}
                variant="contained"
                color="secondary"
                onClick={() => handleAddDialogClose(null)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} align="right">
              <Button
                disabled={this.state.loading}
                variant="contained"
                color="primary"
                onClick={() => this.handleAdd()}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const AddDialogWrapper = (props) => {
  const [saveMutation] = useMutation(RiskGroupsCreateMutation);
  const notyf = useContext(NotyfContext);

  return (
    <AddDialog {...props} notyf={notyf} addGroupMutHandler={saveMutation} />
  );
};

export default AddDialogWrapper;
