import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import mapConnection from '../../../utils/connectionMapper';

const FILTERS_QUERY = gql`
  query userFilters {
    userFilters {
      edges {
        node {
          name
          filtersString
          sortDetailsString
        }
      }
    }
  }
`;

const withQuery =
  (WrappedComponent) =>
  ({ storeFilters, ...rest }) => {
    const { loading, data } = useQuery(FILTERS_QUERY);
    let userFilters = [];

    if (data) {
      userFilters = mapConnection(data.userFilters);
    }

    return (
      <WrappedComponent
        userFilters={storeFilters === null ? userFilters : storeFilters}
        loading={loading}
        {...rest}
      />
    );
  };

export default withQuery;
