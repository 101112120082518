import React, { Component, useContext } from 'react';
import {
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  withStyles,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import UsersUpdateMutation from '../../mutations/UsersUpdateMutation';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import NotyfContext from '../../contexts/NotyfContext';
import { useMutation } from '@apollo/client';
import { displayErrorMessage } from '../../utils/displayMessage';

class EditDialog extends Component {
  handleSave = async () => {
    const { handleDialogClose, groups, editMutHandler, notyf } = this.props;
    const group = groups.find((g) => g.name === this.state.user.riskGroup);

    try {
      let result = await editMutHandler({
        variables: {
          input: {
            userId: this.state.user.id,
            firstName: this.state.user.firstName,
            lastName: this.state.user.lastName,
            email: this.state.user.email,
            position: this.state.user.position,
            abbreviation: this.state.user.abbreviation,
            phone: this.state.user.phone,
            office: this.state.user.office,
            role: this.state.user.role,
            riskGroupId: group ? parseInt(group.id) : null,
          },
        },
      });

      if (
        result != null &&
        result.data.adminUsersUpdate != null &&
        result.data.adminUsersUpdate.errors != null &&
        result.data.adminUsersUpdate.errors.length > 0
      ) {
        displayErrorMessage(
          notyf,
          result.data.adminUsersUpdate.errors[0].message
        );
      } else {
        handleDialogClose(result.data.adminUsersUpdate.user);
      }
    } catch (err) {
      console.error(err);
      displayErrorMessage(notyf, err.message);
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.user ? prevProps.user.id : -1) !==
      (this.props.user ? this.props.user.id : -1)
    ) {
      this.setState({ ...this.state, user: this.props.user });
    }
  }

  render() {
    const { dialogOpen, handleDialogClose, classes, groups } = this.props;

    return (
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Edit user</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="standard-multiline-flexible"
                  label="Id"
                  value={this.state.user ? this.state.user.id : ''}
                  style={{ width: 500 }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="standard-multiline-flexible"
                  label="First name"
                  value={this.state.user ? this.state.user.firstName : ''}
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      user: {
                        ...this.state.user,
                        firstName: event.target.value,
                      },
                    });
                  }}
                  style={{ width: 500 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="standard-multiline-flexible"
                  label="Last name"
                  value={this.state.user ? this.state.user.lastName : ''}
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      user: {
                        ...this.state.user,
                        lastName: event.target.value,
                      },
                    });
                  }}
                  style={{ width: 500 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="standard-multiline-flexible"
                  label="Email"
                  value={this.state.user ? this.state.user.email : ''}
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      user: { ...this.state.user, email: event.target.value },
                    });
                  }}
                  style={{ width: 500 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="standard-multiline-flexible"
                  label="Position"
                  value={this.state.user ? this.state.user.position : ''}
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      user: {
                        ...this.state.user,
                        position: event.target.value,
                      },
                    });
                  }}
                  style={{ width: 500 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="standard-multiline-flexible"
                  label="Abbreviation"
                  value={this.state.user ? this.state.user.abbreviation : ''}
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      user: {
                        ...this.state.user,
                        abbreviation: event.target.value,
                      },
                    });
                  }}
                  style={{ width: 500 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="standard-multiline-flexible"
                  label="Phone"
                  value={this.state.user ? this.state.user.phone : ''}
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      user: { ...this.state.user, phone: event.target.value },
                    });
                  }}
                  style={{ width: 500 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="standard-multiline-flexible"
                  label="Office"
                  value={this.state.user ? this.state.user.office : ''}
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      user: { ...this.state.user, office: event.target.value },
                    });
                  }}
                  style={{ width: 500 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Risk group
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.user ? this.state.user.riskGroup : ''}
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      user: {
                        ...this.state.user,
                        riskGroup: groups.find(
                          (g) => g.name === event.target.value
                        ).name,
                      },
                    });
                  }}
                >
                  {groups &&
                    groups.map((group) => (
                      <MenuItem key={group.id} value={group.name}>
                        {group.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Role</FormLabel>
                <RadioGroup
                  row
                  aria-label="position"
                  name="role"
                  value={this.state.user ? this.state.user.role : ''}
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      user: { ...this.state.user, role: event.target.value },
                    });
                  }}
                >
                  <FormControlLabel
                    value="member"
                    control={<Radio color="primary" />}
                    label="Member"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="admin"
                    control={<Radio color="primary" />}
                    label="Admin"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="risk_coordinator"
                    control={<Radio color="primary" />}
                    label="Risk Coordinator"
                    labelPlacement="start"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleDialogClose(null)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} align="right">
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.handleSave()}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}
const styles = (theme) => ({
  formControl: {
    width: '300px',
  },
});

const EditDialogWrapper = (props) => {
  const [updateMutation] = useMutation(UsersUpdateMutation);
  const notyf = useContext(NotyfContext);

  return (
    <EditDialog {...props} notyf={notyf} editMutHandler={updateMutation} />
  );
};

export default withStyles(styles)(EditDialogWrapper);
