import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
} from 'react';

import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';

import {
  ActionMap,
  AuthState,
  AuthUser,
  CognitoContextType,
} from '../types/auth';

import { cognitoConfig } from '../utils/config';
import store from '../store/store';
import { clearToken, saveToken } from '../actions/token-actions';

const INITIALIZE = 'INITIALIZE';
const SIGN_OUT = 'SIGN_OUT';

const UserPool = new CognitoUserPool({
  UserPoolId: cognitoConfig.userPoolId || '',
  ClientId: cognitoConfig.clientId || '',
});

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  token: undefined,
};

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
    token: string | undefined;
  };
  [SIGN_OUT]: undefined;
};

type CognitoActions =
  // eslint-disable-next-line prettier/prettier
  ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>];

const reducer = (state: AuthState, action: CognitoActions) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user, token } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      token: token,
    };
  }
  if (action.type === SIGN_OUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      token: undefined,
    };
  }
  return state;
};

const AuthContext = createContext<CognitoContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getUserAttributes = useCallback(
    (currentUser: CognitoUser): Record<string, any> =>
      new Promise((resolve, reject) => {
        currentUser.getUserAttributes((err, attributes) => {
          if (err) {
            reject(err);
          } else {
            const results: Record<string, any> = {};

            attributes?.forEach((attribute) => {
              results[attribute.Name] = attribute.Value;
            });
            resolve(results);
          }
        });
      }),
    []
  );

  const getSession = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser();
        if (user) {
          user.getSession(
            async (err: Error | null, session: CognitoUserSession | null) => {
              if (err) {
                reject(err);
              } else {
                const attributes = await getUserAttributes(user);
                const token = session?.getAccessToken().getJwtToken();

                dispatch({
                  type: INITIALIZE,
                  payload: {
                    isAuthenticated: true,
                    user: attributes,
                    token: token,
                  },
                });

                resolve({
                  user,
                  session,
                  headers: { Authorization: token },
                });
              }
            }
          );
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
              token: undefined,
            },
          });
        }
      }),
    [getUserAttributes]
  );

  const initialize = useCallback(async () => {
    try {
      await getSession();
    } catch {
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
          token: undefined,
        },
      });
    }
  }, [getSession]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const signIn = useCallback(
    (email, password) =>
      new Promise((resolve, reject) => {
        store.dispatch(clearToken());
        localStorage.removeItem('redux');
        const user = new CognitoUser({
          Username: email,
          Pool: UserPool,
        });

        const authDetails = new AuthenticationDetails({
          Username: email,
          Password: password,
        });
        user.authenticateUser(authDetails, {
          onSuccess: async (data) => {
            await getSession();
            const token = data?.getAccessToken().getJwtToken();
            await store.dispatch(saveToken(token));
            resolve(data);
          },
          onFailure: (err) => {
            console.log(err);
            reject(err);
          },
          newPasswordRequired: () => {
            throw new Error('New password required');
            //resolve({ message: 'New password required' });
          },
        });
      }),
    [getSession]
  );

  const signOut = () => {
    const user = UserPool.getCurrentUser();
    if (user) {
      user.signOut();
      dispatch({ type: SIGN_OUT });
      store.dispatch(clearToken());
      localStorage.removeItem('redux');
    }
  };

  const resetPassword = (email: string) => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    cognitoUser.forgotPassword({
      onSuccess: function(result) {
          console.log('Cognito - forgot password success result:',  result);
      },
      onFailure: function(err) {
        alert(err);
      },
  });
  };

  const resetPasswordConfirmation = (email: string, code: string, newPassword: string, onSuccess: () => any, onFailure: (error: any) => any) => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    cognitoUser.confirmPassword(`${code}`, newPassword, {
      onSuccess: onSuccess,
      onFailure: onFailure,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'cognito',
        user: {
          displayName: state?.user?.name || 'Undefined',
          role: 'user',
          ...state.user,
        },
        signIn,
        signOut,
        resetPassword,
        resetPasswordConfirmation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
