import React from 'react';
import { useQuery } from '@apollo/client';
import mapConnection from '../../utils/connectionMapper';
import gql from 'graphql-tag';

const USERS_QUERY = gql`
  query users {
    users {
      edges {
        node {
          id
          name
          firstName
          lastName
          email
          createdAt
          phone
          position
          abbreviation
          office
          role
          status
          riskGroup
        }
      }
    }
    riskGroups {
      edges {
        node {
          id
          name
          leader {
            id
            email
            name
          }
          parentGroup {
            id
            name
          }
          abbreviation
          users {
            id
            email
            name
          }
          groupChildrenIds
        }
      }
    }
  }
`;

const withQuery = (WrappedComponent) => (props) => {
  const { loading, data } = useQuery(USERS_QUERY);

  const users = data ? mapConnection(data.users) : [];
  const groups = data ? mapConnection(data.riskGroups) : [];
  return (
    <WrappedComponent
      {...props}
      users={users}
      groups={groups}
      loading={loading}
    />
  );
};

export default withQuery;
