const displayMessage = (notyf, message, type, duration = 5000) => {
  console.log(`Message[${type}]: ${message}`);
  notyf.open({
    type: type,
    message: message,
    duration: duration,
    ripple: true,
    dismissible: false,
    position: {
      x: 'left',
      y: 'bottom',
    },
  });
};

export const displaySuccessMessage = (notyf, message) => {
  displayMessage(notyf, message, 'success');
};

export const displayWarningMessage = (notyf, message) => {
  displayMessage(notyf, message, 'warning');
};

export const displayInfoMessage = (notyf, message) => {
  displayMessage(notyf, message, 'default');
};

export const displayErrorMessage = (notyf, message) => {
  displayMessage(notyf, message, 'danger');
};

export default displayMessage;
