// Layouts
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';

import RiskEntitiesPage from './components/RiskEntities/RiskEntitiesPage';
import DashboardPage from './components/Dashboard/DashboardPage';
import DictionariesPage from './components/Dictionaries/DictionariesPage';
import RiskGroupsPage from './components/RiskGroups/RiskGroupsPage';
import UsersPage from './components/Users/UsersPage';
import ProfilePage from './components/Profile/ProfilePage';
import MessagesPage from './components/Messages/MessagesPage';
// import AnalysisDataPage from './components/Analysis/AnalysisDataPage';
// import RiskReportPage from './components/RiskReport/RiskReportPage';
// import StatisticsPage from './components/Statistics/StatisticssPage';
import SummaryTablePage from './components/SummaryTable/SummaryTablePage';
import ManualPage from './components/Manual/ManualPage';
import NewReportConfigPage from './components/Config/NewReportConfigPage';
// import PriorityConfigPage from './components/Config/PriorityConfigPage';

// Guards
import AuthGuard from './components/guards/AuthGuard';

// Auth
import Page500 from './pages/auth/Page500';
import Page404 from './pages/auth/Page404';
import SignIn from './pages/auth/SignIn';
import ResetPassword from './pages/auth/ResetPassword';
import ResetPasswordConfirmation from './pages/auth/ResetPasswordConfirmation';

const routes = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <DashboardPage />,
      },
    ],
  },
  {
    path: 'auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'sign-in',
        element: <SignIn />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
      {
        path: 'reset-password-confirmation/:email',
        element: <ResetPasswordConfirmation />,
      },
      {
        path: '404',
        element: <Page404 />,
      },
      {
        path: '500',
        element: <Page500 />,
      },
    ],
  },
  {
    path: 'docs',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <ManualPage />,
      },
    ],
  },
  {
    path: 'risks',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'entities',
        element: <RiskEntitiesPage />,
      },
    ],
  },
  {
    path: 'analytics',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'summary',
        element: <SummaryTablePage />,
      },
    ],
  },
  {
    path: 'settings',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'profile',
        element: <ProfilePage />,
      },
      {
        path: 'messages',
        element: <MessagesPage />,
      },
    ],
  },
  {
    path: 'admin',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'dictionaries',
        element: <DictionariesPage />,
      },
      {
        path: 'groups',
        element: <RiskGroupsPage />,
      },
      {
        path: 'users',
        element: <UsersPage />,
      },
      {
        path: 'new-report',
        element: <NewReportConfigPage />,
      },
    ],
  },
  {
    path: '*',
    element: <AuthLayout />,
    children: [
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
