import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Spinner from '../../UI/Spinner';
import {
  DEFAULT_GLOBALITY_FILTER,
  DEFAULT_RANGE_FILTER,
  DEFAULT_PHASE_FILTER,
  DEFAULT_STATUS_FILTER,
  DEFAULT_PRIORITY_FILTER,
} from '../../../utils/consts';

import { Pie } from 'react-chartjs-2';
import { Card, Dropdown, Table } from 'react-bootstrap';
import { MoreHorizontal } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';

import usePalette from '../../../hooks/usePalette';
import store from '../../../store/store';
import priorityColor from '../../../utils/priorityColor';

const STATS_QUERY = gql`
  query statistics(
    $reportName: String
    $filter: [RiskStatsFilterInputObject!]
  ) {
    priorityStatistics(reportName: $reportName, filter: $filter) {
      priorityId
      ids
      value
    }
    currentReport {
      title
    }
  }
`;

const withQuery = (WrappedComponent) => (props) => {
  const { loading, data } = useQuery(STATS_QUERY, {
    variables: {
      reportName: props.reportName,
      filter: [
        {
          name: 'phase',
          includeValues: props.filters
            ? props.filters.phase
            : DEFAULT_PHASE_FILTER,
        },
        {
          name: 'status',
          includeValues: props.filters
            ? props.filters.status
            : DEFAULT_STATUS_FILTER,
        },
        {
          name: 'globality',
          includeValues: props.filters
            ? props.filters.globality
            : DEFAULT_GLOBALITY_FILTER,
        },
        {
          name: 'range',
          includeValues: props.filters
            ? props.filters.range
            : DEFAULT_RANGE_FILTER,
        },
        {
          name: 'priority',
          includeValues: props.filters
            ? props.filters.priority
            : DEFAULT_PRIORITY_FILTER,
        },
      ],
    },
  });

  const stats = data ? data.priorityStatistics : [];
  const reportName = data ? data.currentReport.title : '';

  return (
    <WrappedComponent
      {...props}
      stats={stats}
      loading={loading}
      reportName={props.reportName || reportName}
    />
  );
};

const Chart = (props) => {
  const { stats } = props;
  const palette = usePalette();
  const priorityDict = store.getState().dictionary['Dictionaries::Priority'];

  const priorityDictionary = priorityDict
    ? priorityDict.sort((a, b) => a.level - b.level)
    : [];

  const statsData = priorityDictionary.map((p) => {
    const val = stats.find((s) => s.priorityId === p.id);
    return val ? val.value : 0;
  });

  const statsColors = priorityDictionary.map((p) => {
    return palette[priorityColor(p.id, '')[0]];
  });

  const getPriorityValue = (id) => {
    const v = stats.find((s) => s.priorityId === id);
    return v ? v.value : 0;
  };

  const data = {
    labels: priorityDictionary.map((p) => p.value),
    datasets: [
      {
        data: statsData,
        backgroundColor: statsColors,
        borderWidth: 5,
        borderColor: palette.white,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    legend: {
      display: false,
    },
  };

  return (
    <div className="align-self-center w-100">
      <div className="py-3">
        <div className="chart chart-xs">
          <Pie data={data} options={options} />
        </div>
      </div>

      <Table className="mb-0">
        <thead>
          <tr>
            <th>Priority level</th>
            <th className="text-end">Value</th>
            <th className="text-end">Increase</th>
          </tr>
        </thead>
        <tbody>
          {priorityDictionary.map((d) => (
            <tr>
              <td>
                <FontAwesomeIcon
                  icon={faSquare}
                  className={priorityColor(d.id, 'text-')[0]}
                />{' '}
                {d.description}
              </td>
              <td className="text-end">{getPriorityValue(d.id)}</td>
              <td className="text-end text-success">+0 %</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const PrioritiesChart = (props) => {
  const { loading, stats, reportName } = props;
  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>-</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Card.Title className="mb-0">Report: {reportName}</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        {loading ? <Spinner /> : stats && <Chart stats={stats} />}
      </Card.Body>
    </Card>
  );
};

export default withQuery(PrioritiesChart);
