import React from 'react';
import withQuery from './withQuery';
import RiskEntitiesTable from './RiskEntitiesTable';

const RiskEntitiesPage = (props: any) => {
  const ListWithData = withQuery(RiskEntitiesTable);

  return <ListWithData {...props} />;
};

export default withQuery(RiskEntitiesPage);
