import { Paper } from '@material-ui/core';
import { useCallback, useMemo, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import {
  OvercostFormatter,
  OvertimeFormatter,
  PriorityFormatter,
  ProbabilityFormatter,
} from '../RiskEntities/formatters';

const DetailsTable = (props) => {
  const { records, loading, sortDirection } = props;
  const [sortColumns, setSortColumns] = useState([]);
  const onSortColumnsChange = useCallback((sortColumns) => {
    setSortColumns(sortColumns.slice(-1));
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => {
    return [
      {
        name: 'ID',
        key: 'identifier',
        width: 100,
        formatter: ({ row }) => (
          <PriorityFormatter
            priorityId={row.priorityId}
            value={row.identifier}
          />
        ),
      },
      {
        name: 'Specification',
        key: 'specification',
      },
      {
        name: 'Cost',
        key: 'overcost',
        width: 140,
        formatter: ({ row }) => <OvercostFormatter value={row.overcost} />,
      },
      {
        name: 'Time',
        key: 'overtime',
        width: 160,
        formatter: ({ row }) => (
          <OvertimeFormatter value={row.overtime} row={row} />
        ),
      },
      {
        name: 'Probability',
        key: 'probability',
        width: 100,
        formatter: ({ row }) => (
          <ProbabilityFormatter value={row.probability} />
        ),
      },
    ];
  });

  const sortedRows = useMemo(() => {
    if (sortColumns.length === 0) return records;
    const { columnKey, direction } = sortColumns[0];
    let sortedRows = [...records];

    switch (columnKey) {
      case 'identifier':
      case 'title':
      case 'specification':
      case 'migrationScenario':
      case 'status':
      case 'owner':
      case 'category':
      case 'subcategory':
      case 'strategy':
      case 'phase':
      case 'priority':
      case 'impact':
      case 'globality':
      case 'range':
      case 'report':
      case 'probability':
      case 'riskGroup':
      case 'overtime':
      case 'overcost':
        sortedRows = sortedRows.sort((a, b) =>
          (a[columnKey] || '').localeCompare(b[columnKey])
        );
        break;
      default:
    }

    return direction === 'DESC' ? sortedRows.reverse() : sortedRows;
  }, [records, sortColumns]);

  return (
    <Paper>
      {loading ? (
        <div>Loading ...</div>
      ) : (
        <ReactDataGrid
          rowKeyGetter={(row) => row.id}
          columns={columns}
          direction={sortDirection}
          onSortColumnsChange={onSortColumnsChange}
          sortColumns={sortColumns}
          rows={sortedRows}
          rowClass={(row) =>
            row.status === 'CLOSED' ? 'highlight' : undefined
          }
          rowHeight={24}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
        />
      )}
    </Paper>
  );
};

export default DetailsTable;
