import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import { Formik } from 'formik';
import { Alert, Button, Form } from 'react-bootstrap';
import { useParams } from 'react-router';

import useAuth from '../../hooks/useAuth';

YupPassword(Yup);

function ResetPasswordConfirmation() {
  const navigate = useNavigate();
  const { resetPasswordConfirmation } = useAuth();

  const { email } = useParams();

  return (
    <Formik
      initialValues={{
        email: email ?? '',
        code: '',
        password: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Must be a valid email')
          .max(255)
          .required('Email is required'),
        password: Yup.string()
          .required('required')
          .min(
            8,
            'password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special'
          )
          .minLowercase(1, 'password must contain at least 1 lower case letter')
          .minUppercase(1, 'password must contain at least 1 upper case letter')
          .minNumbers(1, 'password must contain at least 1 number')
          .minSymbols(1, 'password must contain at least 1 special character'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const onFailure = (error: any) => {
          const message = error.message || 'Something went wrong';

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        };

        const onSuccess = () => {
          navigate(`/auth/sign-in/`);
        };

        resetPasswordConfirmation(
          values.email,
          values.code,
          values.password,
          onSuccess,
          onFailure
        );
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              size="lg"
              type="email"
              name="email"
              placeholder="Enter your email"
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              disabled={true}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Verification code</Form.Label>
            <Form.Control
              size="lg"
              type="number"
              name="code"
              placeholder="Enter verification code"
              value={values.code}
              isInvalid={Boolean(touched.code && errors.code)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.code && (
              <Form.Control.Feedback type="invalid">
                {errors.code}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>New password</Form.Label>
            <Form.Control
              size="lg"
              type="password"
              name="password"
              placeholder="Enter your new password"
              value={values.password}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <div className="text-center mt-3">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              Reset password
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ResetPasswordConfirmation;
