import React, { useContext } from 'react';
import { Button, Col, Modal, Row, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Select from 'react-select';
import { useMutation } from '@apollo/client';
import store from '../../store/store';
import remapEntity from '../../utils/remapRiskTicketFields';
import UpdateRiskEntityMutation from '../../mutations/UpdateRiskEntityMutation';
import NotyfContext from '../../contexts/NotyfContext';
import {
  displayErrorMessage,
  displaySuccessMessage,
} from '../../utils/displayMessage';
import priorityColor from '../../utils/priorityColor';

const schema = Yup.object().shape({
  specification: Yup.string().required().min(5),
  riskGroup: Yup.string().required(),
  owner: Yup.string().required(),
  planOwner: Yup.string().required(),
  category: Yup.string(),
  subcategory: Yup.string(),
  id: Yup.string().required(),
  migrationScenario: Yup.string().nullable(),
  title: Yup.string().nullable(),
  phase: Yup.string(),
  range: Yup.string(),
  globality: Yup.string(),
  status: Yup.string().required(),
  strategy: Yup.string(),
  overtime_value: Yup.number().nullable(),
  overtime_range: Yup.string().nullable(),
  overcost: Yup.string().nullable(),
  probability: Yup.string().nullable(),
});

function findGroup(name, groups) {
  let result = undefined;
  groups.forEach((group) => {
    if (group.name === name) {
      result = group;
    }
  });

  return result;
}
const makeSelectOptions = (
  dictionary,
  key = 'value',
  withoutDefault = false
) => {
  const options = withoutDefault ? [] : [{ label: 'Select ...', value: '' }];
  dictionary &&
    dictionary.forEach((elem) => {
      options.push({ label: elem[key], value: elem[key] });
    });

  return options;
};

const EditRiskModalForm = ({
  updateRiskEntityMutation,
  handleEditModalClose,
  row,
}) => {
  const notyf = useContext(NotyfContext);
  const saveHandler = async (values) => {
    try {
      let result = await updateRiskEntityMutation({
        variables: {
          input: { ...values, id: row.id },
        },
      });

      if (
        result.data.riskEventsUpdate != null &&
        result.data.riskEventsUpdate.errors != null &&
        result.data.riskEventsUpdate.errors.length > 0
      ) {
        displayErrorMessage(
          notyf,
          result.data.riskEventsUpdate.errors[0].message
        );
      } else {
        const updated = remapEntity(result.data.riskEventsUpdate.riskEvent);
        displaySuccessMessage(
          notyf,
          `Risk event: ${updated.identifier} has just been changed`
        );

        handleEditModalClose(updated);
      }
    } catch (err) {
      displayErrorMessage(notyf, err.message);
    }
  };

  const complexValue = (val) => {
    if (!isNaN(val)) {
      try {
        const v = parseFloat(val);
        if (val.startsWith(v.toString())) {
          return val;
        }
      } catch {}
    }
    return '';
  };

  const complexRange = (val, values) => {
    if (typeof val === 'string' || val instanceof String) {
      if (values.includes(val)) {
        return val;
      }
    }

    return '';
  };
  const overtimes = makeSelectOptions(
    store.getState().dictionary['Dictionaries::Overtime']
  );
  const overtimeValues = overtimes.map((ot) => ot.value);
  const overcosts = makeSelectOptions(
    store.getState().dictionary['Dictionaries::Overcost']
  );
  const overcostValues = overcosts.map((ot) => ot.value);
  const probabilities = makeSelectOptions(
    store.getState().dictionary['Dictionaries::Probability']
  );
  const probabilityValues = probabilities.map((ot) => ot.value);

  return (
    <Formik
      validationSchema={schema}
      onSubmit={saveHandler}
      initialValues={{
        id: row.id,
        migrationScenario: row.migrationScenario,
        owner: row.owner,
        phase: row.phase,
        planOwner: row.planOwner,
        range: row.range,
        riskGroup: row.riskGroup,
        specification: row.specification,
        status: row.status,
        strategy: row.strategy,
        category: row.category,
        subcategory: row.subcategory,
        title: row.title,
        globality: row.globality,
        overtime:
          complexValue(row.overtime) ||
          complexRange(row.overtime, overtimeValues),
        overcost: row.overcost,
        probability: row.probability,
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        setFieldValue,
      }) => {
        const { currentUser } = store.getState().users;
        const groups = currentUser.myGroups;
        const groupsOptions = makeSelectOptions(groups, 'name', false);
        const selectedGroup = findGroup(
          values.riskGroup && values.riskGroup,
          groups
        );
        const groupUsersOptions =
          selectedGroup && makeSelectOptions(selectedGroup.users, 'name');
        const subcategories = makeSelectOptions(
          store.getState().dictionary['Dictionaries::Subcategory']
        );
        const categories = makeSelectOptions(
          store.getState().dictionary['Dictionaries::Category']
        );
        const ranges = makeSelectOptions(
          store.getState().dictionary['Dictionaries::Range']
        );
        const phases = makeSelectOptions(
          store.getState().dictionary['Dictionaries::Phase'],
          'value',
          true
        );
        const statuses = makeSelectOptions(
          store.getState().dictionary['Dictionaries::Status'],
          'value',
          true
        );
        const globalities = makeSelectOptions(
          store.getState().dictionary['Dictionaries::Globality'],
          'value',
          true
        );
        const strategies = makeSelectOptions(
          store.getState().dictionary['Dictionaries::Strategy']
        );

        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="vf01">
                <Form.Label>Specification</Form.Label>
                <Form.Control
                  style={{ width: '100%' }}
                  rows={3}
                  as="textarea"
                  name="specification"
                  value={values.specification}
                  onChange={handleChange}
                  isInvalid={!!errors.specification}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.specification}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="vf02">
                <Form.Label>Group</Form.Label>
                <Select
                  type="text"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={groupsOptions}
                  name="riskGroup"
                  value={groupsOptions.find(
                    (f) => values.riskGroup === f.value
                  )}
                  onChange={(option) => {
                    setFieldValue('owner', '');
                    setFieldValue('planOwner', '');
                    setFieldValue('riskGroup', option.value);
                  }}
                />
                {
                  <div
                    style={{ color: 'red', marginTop: '.5rem', fontSize: 10 }}
                  >
                    {errors.riskGroup}
                  </div>
                }
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="vf03">
                <Form.Label>Risk owner</Form.Label>
                <Select
                  type="text"
                  name="owner"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={groupUsersOptions}
                  value={groupUsersOptions.find(
                    (f) => values.owner === f.value
                  )}
                  onChange={(option) => setFieldValue('owner', option.value)}
                  isInvalid={!!errors.owner}
                />
                <div style={{ color: 'red', marginTop: '.5rem', fontSize: 10 }}>
                  {errors.owner}
                </div>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="vf04">
                <Form.Label>Category</Form.Label>
                <Select
                  type="text"
                  name="category"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={categories}
                  value={categories.find((f) => values.category === f.value)}
                  onChange={(option) => setFieldValue('category', option.value)}
                  isInvalid={!!errors.category}
                />
                <div style={{ color: 'red', marginTop: '.5rem', fontSize: 10 }}>
                  {errors.category}
                </div>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="vf05">
                <Form.Label>Subcategory</Form.Label>
                <Select
                  type="text"
                  name="subcategory"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={subcategories}
                  value={subcategories.find(
                    (f) => values.subcategory === f.value
                  )}
                  onChange={(option) =>
                    setFieldValue('subcategory', option.value)
                  }
                  isInvalid={!!errors.subcategory}
                />
                <div style={{ color: 'red', marginTop: '.5rem', fontSize: 10 }}>
                  {errors.subcategory}
                </div>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Col>
                <Row>
                  <Form.Group as={Col} md="12" controlId="vf06">
                    <Form.Label>Overtime [h]</Form.Label>
                    <Select
                      type="text"
                      name="overtime"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={overtimes}
                      value={overtimes.find(
                        (f) =>
                          complexRange(values.overtime, overtimeValues) ===
                          f.value
                      )}
                      onChange={(option) =>
                        setFieldValue('overtime', option.value)
                      }
                      isInvalid={!!errors.overtime}
                    />
                  </Form.Group>
                </Row>
                <Row className="mt-2">
                  <Form.Group as={Col} md="12" controlId="vf07">
                    <Form.Control
                      size="lg"
                      style={{ width: '100%' }}
                      name="overtime"
                      value={complexValue(values.overtime)}
                      onChange={handleChange}
                      isInvalid={!!errors.overtime}
                    />
                  </Form.Group>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Form.Group as={Col} md="12" controlId="vf08">
                    <Form.Label>Overcost [M CAD]</Form.Label>
                    <Select
                      type="text"
                      name="overcost"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={overcosts}
                      value={overcosts.find(
                        (f) =>
                          complexRange(values.overcost, overcostValues) ===
                          f.value
                      )}
                      onChange={(option) =>
                        setFieldValue('overcost', option.value)
                      }
                      isInvalid={!!errors.overtime}
                    />
                  </Form.Group>
                </Row>
                <Row className="mt-2">
                  <Form.Group as={Col} md="12" controlId="vf09">
                    <Form.Control
                      size="lg"
                      style={{ width: '100%' }}
                      name="overcost"
                      value={complexValue(values.overcost)}
                      onChange={handleChange}
                      isInvalid={!!errors.overcost}
                    />
                  </Form.Group>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Form.Group as={Col} md="12" controlId="vf10">
                    <Form.Label>Likelihood [%]</Form.Label>
                    <Select
                      type="text"
                      name="probability"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={probabilities}
                      value={probabilities.find(
                        (f) =>
                          complexRange(
                            values.probability,
                            probabilityValues
                          ) === f.value
                      )}
                      onChange={(option) =>
                        setFieldValue('probability', option.value)
                      }
                      isInvalid={!!errors.probability}
                    />
                  </Form.Group>
                </Row>
                <Row className="mt-2">
                  <Form.Group as={Col} md="12" controlId="vf11">
                    <Form.Control
                      size="lg"
                      style={{ width: '100%' }}
                      name="probability"
                      value={complexValue(values.probability)}
                      onChange={handleChange}
                      isInvalid={!!errors.probability}
                    />
                  </Form.Group>
                </Row>
              </Col>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="vf12">
                <Form.Label>Response plan</Form.Label>
                <Form.Control
                  style={{ width: '100%' }}
                  rows={3}
                  as="textarea"
                  name="migrationScenario"
                  value={values.migrationScenario}
                  onChange={handleChange}
                  isInvalid={!!errors.migrationScenario}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.migrationScenario}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="vf13">
                <Form.Label>Response plan owner</Form.Label>
                <Select
                  type="text"
                  name="planOwner"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={groupUsersOptions}
                  value={groupUsersOptions.find(
                    (f) => values.planOwner === f.value
                  )}
                  onChange={(option) =>
                    setFieldValue('planOwner', option.value)
                  }
                  isInvalid={!!errors.planOwner}
                />
                <div style={{ color: 'red', marginTop: '.5rem', fontSize: 10 }}>
                  {errors.planOwner}
                </div>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="vf14">
                <Form.Label>Status</Form.Label>
                <Select
                  type="text"
                  name="status"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={statuses}
                  value={statuses.find((f) => values.status === f.value)}
                  onChange={(option) => setFieldValue('status', option.value)}
                  isInvalid={!!errors.status}
                />
                <div style={{ color: 'red', marginTop: '.5rem', fontSize: 10 }}>
                  {errors.status}
                </div>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="vf15">
                <Form.Label>Risk type</Form.Label>
                <Select
                  type="text"
                  name="globality"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={globalities}
                  value={globalities.find((f) => values.globality === f.value)}
                  onChange={(option) =>
                    setFieldValue('globality', option.value)
                  }
                  isInvalid={!!errors.globality}
                />
                <div style={{ color: 'red', marginTop: '.5rem', fontSize: 10 }}>
                  {errors.globality}
                </div>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="vf16">
                <Form.Label>Strategy</Form.Label>
                <Select
                  type="text"
                  name="strategy"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={strategies}
                  value={strategies.find((f) => values.strategy === f.value)}
                  onChange={(option) => setFieldValue('strategy', option.value)}
                  isInvalid={!!errors.strategy}
                />
                <div style={{ color: 'red', marginTop: '.5rem', fontSize: 10 }}>
                  {errors.strategy}
                </div>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="vf17">
                <Form.Label>Range</Form.Label>
                <Select
                  type="text"
                  name="range"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={ranges}
                  value={ranges.find((f) => values.range === f.value)}
                  onChange={(option) => setFieldValue('range', option.value)}
                  isInvalid={!!errors.range}
                />
                <div style={{ color: 'red', marginTop: '.5rem', fontSize: 10 }}>
                  {errors.range}
                </div>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="vf18">
                <Form.Label>Phase</Form.Label>
                <Select
                  type="text"
                  name="phase"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={phases}
                  value={phases.find((f) => values.phase === f.value)}
                  onChange={(option) => setFieldValue('phase', option.value)}
                  isInvalid={!!errors.phase}
                />
                <div style={{ color: 'red', marginTop: '.5rem', fontSize: 10 }}>
                  {errors.phase}
                </div>
              </Form.Group>
            </Row>
            <Row className="m-1">
              <Button type="submit">Save changes</Button>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

const EditRiskModal = (props) => {
  const { editModalOpen, row, handleEditModalClose } = props;
  const index = 0;
  const size = 'lg';

  return (
    <React.Fragment key={index}>
      <Modal
        show={editModalOpen}
        onHide={() => handleEditModalClose(null)}
        size={size}
      >
        <Modal.Header closeButton>
          <div>
            Edit the risk: &nbsp; <strong>{row && row.identifier}</strong>
          </div>
          &nbsp;
          {row && row.priorityId && (
            <div
              className={`rounded rounded-dark p-2 mb-1 ${
                priorityColor(row.priorityId)[0]
              }`}
              style={{ width: 35 }}
            >
              &nbsp;
            </div>
          )}
        </Modal.Header>
        <Modal.Body>
          <EditRiskModalForm {...props} />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

const withMutation = (WrappedComponent) => (props) => {
  const [updateRiskEntityMutation] = useMutation(UpdateRiskEntityMutation);
  return (
    <WrappedComponent
      {...props}
      updateRiskEntityMutation={updateRiskEntityMutation}
    />
  );
};

export default withMutation(EditRiskModal);
