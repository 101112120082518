import React from 'react';
import Spinner from '../../UI/Spinner';
import HistoryRows from './HistoryRows';
import withQuery from './withQuery';

const HistoryTable = (props) => {
  return (
    <div>
      {props.loading ? <Spinner /> : props.rows && <HistoryRows {...props} />}
    </div>
  );
};

export default withQuery(HistoryTable);
