import gql from 'graphql-tag';

const UpsertUserFiltersMutation = gql`
  mutation UpsertUserFilterMutation($input: UpsertUserFilterMutationInput!) {
    userFiltersUpsert(input: $input) {
      filters {
        id
        name
        filtersString
        sortDetailsString
      }
      errors {
        name
        message
      }
    }
  }
`;

export default UpsertUserFiltersMutation;
