import gql from 'graphql-tag';

const UsersProfileUpdateMutation = gql`
  mutation UsersProfileUpdate($input: UpdateUserProfileMutationInput!) {
    usersProfileUpdate(input: $input) {
      user {
        id
        email
        firstName
        lastName
        name
        phone
        position
        abbreviation
        office
        leaderGroups
        riskGroup
        createdAt
        avatarUrl
      }
      errors {
        name
        message
      }
    }
  }
`;

export default UsersProfileUpdateMutation;
