import gql from 'graphql-tag';

const CombineRiskEntityMutation = gql`
  mutation Combine($input: CombineMutationInput!) {
    riskEventsCombine(input: $input) {
      riskEvents {
        id
        identifier
        specification
        migrationScenario
        owner {
          name
        }
        createdBy {
          name
        }
        updatedBy {
          name
        }

        category {
          label
        }
        subcategory {
          label
        }
        strategy {
          label
        }
        phase {
          label
        }
        status {
          label
        }
        priority {
          label
        }
        impact {
          label
        }
        globality {
          label
        }
        range {
          label
        }
        reportingCycle {
          title
        }
        riskGroup {
          name
        }
        probability
        overtime
        overcost
        title
        canWrite
      }

      errors {
        name
        message
      }
    }
  }
`;

export default CombineRiskEntityMutation;
