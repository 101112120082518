import withQuery from './withQuery';
import DetailsTable from './DetailsTable';
import { Paper, Popper, withStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Draggable from 'react-draggable';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const PreviewWindow = (props) => {
  const { isWindowOpen, setIsWindowOpen, anchorEl, ...rest } = props;
  const RisksDetailsTable = withQuery(DetailsTable);

  const handleClose = () => {
    setIsWindowOpen(false);
  };

  return (
    <Popper open={isWindowOpen} anchorEl={anchorEl} onClose={handleClose}>
      <Draggable>
        <Paper style={{ width: 800 }} elevation={5}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Risks list
          </DialogTitle>
          <DialogContent dividers>
            <RisksDetailsTable {...rest} />
          </DialogContent>
        </Paper>
      </Draggable>
    </Popper>
  );
};

export default PreviewWindow;
