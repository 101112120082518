import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import DataGrid, { Row as GridRow } from 'react-data-grid';
import EditRiskModal from './EditRiskModal';
import CommentsModal from './CommentsModal';
import HistoryModal from './HistoryModal';
import remapEntity from '../../utils/remapRiskTicketFields';
import SplitModal from './SplitModal';
import CombineModal from './CombineModal';
import Spinner from '../UI/Spinner';
import { createPortal } from 'react-dom';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import './react-contextmenu.css';
import { CSVLink } from 'react-csv';
import store from '../../store/store';
import AttachmentsModal from './AttachmentsModal';
import columnsFun from './table/columns';
import FilterContext from './table/FilterContext';
import FilterSelector from './FilterSelector';
import defaultFilters from './FilterSelector/defaultFilters';
import NewRiskModal from './NewRiskModal';
import NotyfContext from '../../contexts/NotyfContext';
import {
  displayInfoMessage,
  displayWarningMessage,
} from '../../utils/displayMessage';

function EmptyRowsRenderer() {
  return <div style={{ textAlign: 'center', margin: 20 }}>No records</div>;
}

export function RiskEntitiesList(props) {
  const { entities, currentUser, sortDirection } = props;
  const users = store.getState().users.allUsers;
  const notyf = useContext(NotyfContext);

  const csvBtn = React.useRef();

  const [rows, setRows] = useState(entities);
  const [sortColumns, setSortColumns] = useState([]);
  const onSortColumnsChange = useCallback((sortColumns) => {
    setSortColumns(sortColumns.slice(-1));
  }, []);

  const [selectedRows, setSelectedRows] = useState(() => new Set());
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [commentsModalOpen, setCommentsModalOpen] = useState(false);
  const [attachmentsModalOpen, setAttachmentsModalOpen] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [splitModalOpen, setSplitModalOpen] = useState(false);
  const [combineModalOpen, setCombineModalOpen] = useState(false);
  const [splitRow, setSplitRow] = useState(null);
  const [combineRows, setCombineRows] = useState(null);
  const [editModalRow, setEditModalRow] = useState(null);
  const [commentsModalRow, setCommentsModalRow] = useState(null);
  const [historyModalRow, setHistoryModalRow] = useState(null);
  const [attachmentsModalRow, setAttachmentsModalRow] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [storeFilters, setStoreFilters] = useState(null);

  const [filters, setFilters] = useState(defaultFilters);

  const isAdmin = currentUser.role === 'admin';

  const riskColumns = store.getState().riskColumns.columns;
  const columns = useMemo(() => {
    const phasesDictionary =
      store.getState().dictionary['Dictionaries::Phase'] || [];
    const impactDictionary =
      store.getState().dictionary['Dictionaries::Impact'] || [];
    const statusesDictionary =
      store.getState().dictionary['Dictionaries::Status'] || [];
    const overtimeDictionary =
      store.getState().dictionary['Dictionaries::Overtime'] || [];
    const overcostDictionary =
      store.getState().dictionary['Dictionaries::Overcost'] || [];
    const strategiesDictionary =
      store.getState().dictionary['Dictionaries::Strategy'] || [];
    const probabilitiesDictionary =
      store.getState().dictionary['Dictionaries::Probability'] || [];
    const subCategoriesDictionary =
      store.getState().dictionary['Dictionaries::Subcategory'] || [];
    const categoriesDictionary =
      store.getState().dictionary['Dictionaries::Category'] || [];
    const prioritiesDictionary =
      store.getState().dictionary['Dictionaries::Priority'] || [];
    const rangesDictionary =
      store.getState().dictionary['Dictionaries::Range'] || [];
    const globalitiesDictionary =
      store.getState().dictionary['Dictionaries::Globality'] || [];
    return columnsFun({
      riskColumns,
      isAdmin,
      setFilters,
      users,
      categoriesDictionary,
      subCategoriesDictionary,
      overcostDictionary,
      overtimeDictionary,
      impactDictionary,
      probabilitiesDictionary,
      prioritiesDictionary,
      strategiesDictionary,
      statusesDictionary,
      phasesDictionary,
      rangesDictionary,
      globalitiesDictionary,
    });
  }, [riskColumns, isAdmin, users]);

  function handleAddModalClose(rowCreated) {
    if (rowCreated !== undefined && rowCreated !== null) {
      const myRows = rows.slice();
      myRows.push(rowCreated);
      setRows(myRows);
    }
  }

  function handleSplitModalClose(splittedRows) {
    if (typeof splittedRows !== undefined && splittedRows != null) {
      const newRows = rows.filter(function (elem) {
        return elem.id !== splitRow?.id;
      });
      setRows([...splittedRows, ...newRows]);
    }
    setSplitModalOpen(false);
    setSplitRow(null);
  }

  function handleCombineModalClose(cRows) {
    if (typeof cRows !== undefined && cRows != null) {
      const combineRowIds = combineRows?.map((c) => c.id);
      const newRows = rows.filter(function (elem) {
        return !combineRowIds?.includes(elem.id);
      });

      setRows([...cRows, ...newRows]);
    }
    setCombineModalOpen(false);
    setCombineRows(null);
  }

  function handleCommentsModalClose() {
    setCommentsModalOpen(false);
    setCommentsModalRow(null);
  }

  function handleHistoryModalClose() {
    setHistoryModalOpen(false);
    setHistoryModalRow(null);
  }

  function handleAttachmentsModalClose() {
    setAttachmentsModalOpen(false);
    setAttachmentsModalRow(null);
  }

  function handleEditModalClose(updated) {
    if (updated !== undefined && updated !== null) {
      const rowId = rows.findIndex((r) => r.id === updated.id);
      const myRows = rows.slice();
      if (rowId > -1) {
        myRows[rowId] = remapEntity(updated);
        setRows(myRows);
      }
    }

    setEditModalOpen(false);
  }

  function onRowDoubleClick(row, rowIdx) {
    handleEditModalOpen({}, { rowIdx: rowIdx });
  }

  function handleSplitModalOpen(e, { rowIdx }) {
    const row = sortedRows[rowIdx];
    if (row.canWrite) {
      setSplitModalOpen(true);
      setSplitRow(rows[rowIdx]);
    } else {
      displayInfoMessage(notyf, 'You have no write permisions!');
    }
  }

  function handleCombineModalOpen(e, { rowIdx }) {
    const myRows = [];
    selectedRows.forEach(function (value) {
      let r = rows.find((r) => typeof r !== undefined && r.id === value);
      if (r) {
        if (r.canWrite) {
          myRows.push(r);
        } else {
          displayInfoMessage(notyf, 'You have no write permisions!');
          return;
        }
      }
    });

    if (myRows.length < 2) {
      displayWarningMessage(notyf, 'You must select at least two rows');
    } else {
      setCombineRows(myRows);
      setCombineModalOpen(true);
    }
  }

  function handleEditModalOpen(e, { rowIdx }) {
    const row = sortedRows[rowIdx];
    if (row.canWrite) {
      setEditModalRow(row);
      setEditModalOpen(true);
    } else {
      displayInfoMessage(notyf, 'You have no write permisions!');
    }
  }

  function handleCommentsModalOpen(e, { rowIdx }) {
    setCommentsModalRow(sortedRows[rowIdx]);
    setCommentsModalOpen(true);
  }

  function handleHistoryModalOpen(e, { rowIdx }) {
    setHistoryModalRow(sortedRows[rowIdx]);
    setHistoryModalOpen(true);
  }

  function handleAttachmentsModalOpen(e, { rowIdx }) {
    setAttachmentsModalRow(sortedRows[rowIdx]);
    setAttachmentsModalOpen(true);
  }

  function RowRenderer(props) {
    const { row, rowIdx } = props;

    return (
      <ContextMenuTrigger
        id="grid-context-menu"
        collect={() => ({ rowIdx: rowIdx })}
      >
        {row.status === 'CLOSED' ? (
          <div className={'row-' + row.status.toLowerCase()}>
            <GridRow
              onDoubleClick={() => onRowDoubleClick(row, rowIdx)}
              {...props}
            />
          </div>
        ) : (
          <GridRow
            onDoubleClick={() => onRowDoubleClick(row, rowIdx)}
            {...props}
          />
        )}
      </ContextMenuTrigger>
    );
  }

  const filteredRows = useMemo(() => {
    return rows.filter((r) => {
      return (
        (filters.title
          ? r.title &&
            r.title.toLowerCase().includes(filters.title.toLowerCase())
          : true) &&
        (filters.identifier
          ? r.identifier.includes(filters.identifier)
          : true) &&
        (filters.specification
          ? r.specification
              .toLowerCase()
              .includes(filters.specification.toLowerCase())
          : true) &&
        (filters.lastComment
          ? (r.lastComment || '')
              .toLowerCase()
              .includes(filters.lastComment.toLowerCase())
          : true) &&
        (filters.lastRcComment
          ? (r.lastRcComment || '')
              .toLowerCase()
              .includes(filters.lastRcComment.toLowerCase())
          : true) &&
        (filters.migrationScenario
          ? (r.migrationScenario || '')
              .toLowerCase()
              .includes(filters.migrationScenario.toLowerCase())
          : true) &&
        (filters.status && filters.status.length > 0
          ? filters.status.includes(r.status)
          : true) &&
        (filters.category && filters.category.length > 0
          ? filters.category.includes(r.category)
          : true) &&
        (filters.subcategory && filters.subcategory.length > 0
          ? filters.subcategory.includes(r.subcategory)
          : true) &&
        (filters.strategy && filters.strategy.length > 0
          ? filters.strategy.includes(r.strategy)
          : true) &&
        (filters.phase && filters.phase.length > 0
          ? filters.phase.includes(r.phase)
          : true) &&
        (filters.priority && filters.priority.length > 0
          ? filters.priority.includes(r.priority)
          : true) &&
        (filters.range && filters.range.length > 0
          ? filters.range.includes(r.range)
          : true) &&
        (filters.report && filters.report.length > 0
          ? filters.report.includes(r.report)
          : true) &&
        (filters.globality && filters.globality.length > 0
          ? filters.globality.includes(r.globality)
          : true) &&
        (filters.impact && filters.impact.length > 0
          ? filters.impact.includes(r.impact)
          : true) &&
        (filters.owner && filters.owner !== 'All'
          ? r.owner === filters.owner
          : true) &&
        (filters.planOwner && filters.planOwner !== 'All'
          ? r.planOwner === filters.planOwner
          : true) &&
        (filters.probability !== 'All'
          ? r.probability === filters.probability
          : true) &&
        (filters.riskGroup !== 'All'
          ? r.riskGroup === filters.riskGroup
          : true) &&
        (filters.overtime !== 'All' ? r.overtime === filters.overtime : true) &&
        (filters.overcost !== 'All' ? r.overcost === filters.overcost : true) &&
        (filters.createdAt[0] !== null
          ? new Date(r.createdAt).getTime() >=
            new Date(filters.createdAt[0]).getTime()
          : true) &&
        (filters.createdAt[1] !== null
          ? new Date(r.createdAt).getTime() <=
            new Date(filters.createdAt[1]).getTime()
          : true) &&
        (filters.updatedAt[0] !== null
          ? new Date(r.updatedAt).getTime() >=
            new Date(filters.updatedAt[0]).getTime()
          : true) &&
        (filters.updatedAt[1] !== null
          ? new Date(r.updatedAt).getTime() <=
            new Date(filters.updatedAt[1]).getTime()
          : true)
      );
    });
  }, [rows, filters]);

  const sortedRows = useMemo(() => {
    if (sortColumns.length === 0) return filteredRows;
    const { columnKey, direction } = sortColumns[0];

    let sortedRows = [...(filteredRows || rows)];

    switch (columnKey) {
      case 'identifier':
      case 'title':
      case 'specification':
      case 'migrationScenario':
      case 'status':
      case 'owner':
      case 'category':
      case 'subcategory':
      case 'strategy':
      case 'phase':
      case 'priority':
      case 'impact':
      case 'globality':
      case 'range':
      case 'report':
      case 'probability':
      case 'riskGroup':
      case 'overtime':
      case 'overcost':
        sortedRows = sortedRows.sort((a, b) =>
          (a[columnKey] || '').localeCompare(b[columnKey])
        );
        break;
      default:
    }

    return direction === 'DESC' ? sortedRows.reverse() : sortedRows;
  }, [rows, filteredRows, sortColumns]);

  function rowKeyGetter(row) {
    return row.id;
  }

  return (
    <Grid item container xs={12} id="myGrid">
      {props.loading ? (
        <Spinner />
      ) : (
        <Grid item container>
          <Grid item container alignItems="center">
            <Grid item xs={7}>
              <FilterSelector
                storeFilters={storeFilters}
                setStoreFilters={setStoreFilters}
                filterName={filterName}
                setFilterName={setFilterName}
                tableFilters={filters}
                setTableFilters={(f) => setFilters(f)}
                tableSortDetails={sortColumns}
                setTableSortDetails={(f) => setSortColumns(f)}
              />
            </Grid>
            <Grid container item xs={5} alignItems="center" justify="flex-end">
              <NewRiskModal handleAddModalClose={handleAddModalClose} />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (
                    typeof csvBtn !== undefined &&
                    typeof csvBtn.current !== undefined &&
                    typeof csvBtn.current.link !== undefined
                  ) {
                    csvBtn.current.link.click();
                  }
                }}
              >
                Export to CSV
              </Button>
              <CSVLink
                data={sortedRows.map((r) => {
                  return {
                    identifier: r.identifier,
                    title: r.title,
                    specification: r.specification,
                    response_plan: r.migrationScenario,
                    status: r.status,
                    owner: r.owner,
                    category: r.category,
                    subcategory: r.subcategory,
                    strategy: r.strategy,
                    phase: r.phase,
                    priority: r.priority,
                    impact: r.impact,
                    globality: r.globality,
                    range: r.range,
                    report: r.report,
                    probability: r.probability,
                    risk_group: r.riskGroup,
                    overtime: r.overtime,
                    overcost: r.overcost,
                  };
                })}
                filename={'export.csv'}
                style={{ display: 'none' }}
                ref={csvBtn}
                target="_blank"
                separator={';'}
              >
                Export to CSV
              </CSVLink>
            </Grid>
          </Grid>
          <FilterContext.Provider value={filters}>
            <DataGrid
              style={{
                width: '100%',
                height: window !== undefined ? window.innerHeight * 0.73 : 100,
                marginTop: 10,
              }}
              rowKeyGetter={rowKeyGetter}
              columns={columns}
              emptyRowsRenderer={EmptyRowsRenderer}
              sortColumns={sortColumns}
              onSortColumnsChange={onSortColumnsChange}
              direction={sortDirection}
              rows={sortedRows}
              selectedRows={selectedRows}
              onSelectedRowsChange={setSelectedRows}
              rowClass={(row) =>
                row.status === 'CLOSED' ? 'highlight' : undefined
              }
              rowRenderer={RowRenderer}
              rowHeight={28}
              headerRowHeight={filters ? 65 : undefined}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
            />
          </FilterContext.Provider>

          {createPortal(
            <ContextMenu id="grid-context-menu">
              <MenuItem onClick={handleEditModalOpen}>Edit</MenuItem>
              <MenuItem onClick={handleHistoryModalOpen}>History</MenuItem>
              <MenuItem onClick={handleCommentsModalOpen}>Comments</MenuItem>
              <MenuItem onClick={handleAttachmentsModalOpen}>
                Attachments
              </MenuItem>
              <MenuItem onClick={handleSplitModalOpen}>Split</MenuItem>
              <MenuItem onClick={handleCombineModalOpen}>Combine</MenuItem>
            </ContextMenu>,
            document.body
          )}
          <EditRiskModal
            editModalOpen={editModalOpen}
            handleEditModalClose={handleEditModalClose}
            row={editModalRow}
          />
          <CommentsModal
            commentsModalOpen={commentsModalOpen}
            handleCommentsModalClose={handleCommentsModalClose}
            row={commentsModalRow}
          />
          <HistoryModal
            historyModalOpen={historyModalOpen}
            handleHistoryModalClose={handleHistoryModalClose}
            row={historyModalRow}
          />
          <AttachmentsModal
            attachmentsModalOpen={attachmentsModalOpen}
            handleAttachmentsModalClose={handleAttachmentsModalClose}
            row={attachmentsModalRow}
          />
          <SplitModal
            splitModalOpen={splitModalOpen}
            handleSplitModalClose={(event) => handleSplitModalClose(event)}
            row={splitRow}
            currentUser={currentUser}
          />
          <CombineModal
            combineModalOpen={combineModalOpen}
            handleCombineModalClose={handleCombineModalClose}
            rows={combineRows}
            users={users}
            currentUser={currentUser}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default RiskEntitiesList;
