import React from 'react';
// import { Button, Navbar, Nav, Form, InputGroup } from 'react-bootstrap';
import { Navbar, Nav } from 'react-bootstrap';

import {
  AlertCircle,
  Bell,
  BellOff,
  Home,
  MessageCircle,
  UserPlus,
  // Search,
} from 'react-feather';

import useSidebar from '../../hooks/useSidebar';

import NavbarDropdown from './NavbarDropdown';
import NavbarDropdownItem from './NavbarDropdownItem';
import NavbarUser from './NavbarUser';

import avatar5 from '../../assets/img/avatars/avatar-5.jpg';

const notifications = [
  {
    type: 'important',
    title: 'New risk',
    description: 'New risk in your group !',
    time: '2h ago',
  },
];

const messages = [
  {
    name: 'Ashley Briggs',
    avatar: avatar5,
    description: 'Dummy message',
    time: '15m ago',
  },
];

//       <Form className="d-none d-sm-inline-block">
//         <InputGroup className="input-group-navbar">
//           <Form.Control placeholder={'Search'} aria-label="Search" />
//           <Button variant="">
//             <Search className="feather" />
//           </Button>
//         </InputGroup>
//       </Form>

const NavbarComponent = () => {
  const { isOpen, setIsOpen } = useSidebar();

  return (
    <Navbar variant="light" expand className="navbar-bg">
      <span
        className="sidebar-toggle d-flex"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <i className="hamburger align-self-center" />
      </span>

      <Navbar.Collapse>
        <Nav className="navbar-align">
          <NavbarDropdown
            header="New Messages"
            footer="Show all messages"
            icon={MessageCircle}
            count={messages.length}
            showBadge
          >
            {messages.map((item, key) => {
              return (
                <NavbarDropdownItem
                  key={key}
                  icon={
                    <img
                      className="avatar img-fluid rounded-circle"
                      src={item.avatar}
                      alt={item.name}
                    />
                  }
                  title={item.name}
                  description={item.description}
                  time={item.time}
                  spacing
                />
              );
            })}
          </NavbarDropdown>
          <NavbarDropdown
            header="New Notifications"
            footer="Show all notifications"
            icon={BellOff}
            count={notifications.length}
          >
            {notifications.map((item, key) => {
              let icon = <Bell size={18} className="text-warning" />;

              if (item.type === 'important') {
                icon = <AlertCircle size={18} className="text-danger" />;
              }

              if (item.type === 'login') {
                icon = <Home size={18} className="text-primary" />;
              }

              if (item.type === 'request') {
                icon = <UserPlus size={18} className="text-success" />;
              }

              return (
                <NavbarDropdownItem
                  key={key}
                  icon={icon}
                  title={item.title}
                  description={item.description}
                  time={item.time}
                />
              );
            })}
          </NavbarDropdown>
          <NavbarUser />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
