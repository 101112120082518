import React from 'react';
import { DialogContent, DialogTitle, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { BACKEND_URL } from '../../utils/config';
import IconButton from '@material-ui/core/IconButton';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DeleteIcon from '@material-ui/icons/Delete';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Draggable from 'react-draggable';
import useAuth from '../../hooks/useAuth';
import Spinner from '../UI/Spinner';

const DOCUMENTS_QUERY = gql`
  query GetRiskEntityDocuments($riskEventId: String!) {
    documents(riskEventId: $riskEventId) {
      id
      filename
      type
      url
    }
  }
`;

const withQuery = (WrappedComponent) => (props) => {
  const { loading, error, data, refetch } = useQuery(DOCUMENTS_QUERY, {
    variables: {
      riskEventId: props?.row?.id,
    },
  });
  const documents = !error && !loading ? data.documents : [];

  return loading ? (
    <Spinner />
  ) : (
    <WrappedComponent
      {...props}
      documents={documents}
      loading={loading}
      refetch={refetch}
    />
  );
};

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const AttachmentsModal = (props) => {
  const { token } = useAuth();
  const headers = {
    authorization: `Bearer ${token}`,
  };
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
  const {
    attachmentsModalOpen,
    handleAttachmentsModalClose,
    row,
    documents,
    refetch,
  } = props;

  const [message, setMessage] = React.useState('');
  const [files, setFiles] = React.useState([]);
  const [attachments, setAttachments] = React.useState(documents);
  const [loading, setLoading] = React.useState(false);
  const [fileToDelete, setFileToDelete] = React.useState(null);

  const uploadFile = async () => {
    if (files.length === 0 || isPayloadTooBig(files)) return;
    setMessage('');
    setLoading(true);
    try {
      var formData = new FormData();
      for (const file of files) {
        formData.append('files[]', file, file.name);
      }
      // for (const attachment of attachments) {
      //   formData.append('files[]', attachment.filename);
      // }
      const res = await fetch(
        `${BACKEND_URL}/documents/risk/${row.id}/upload`,
        {
          method: 'POST',
          body: formData,
          headers: headers,
        }
      );

      if (res.status === 200) {
        setMessage('File Upload Success!');
        setFiles([]);
        const res1 = await refetch({
          riskId: props?.row?.id,
        });
        const { data, loading, error } = res1;
        const documents = !error && !loading ? data.documents : null;

        setAttachments(documents);
      } else {
        res.json().then((body) => {
          setMessage(`Error[${res.status}]: ${body?.errors.join(' ,')}`);
        });
      }
    } catch (err) {
      setMessage(err);
    } finally {
      setLoading(false);
      setTimeout(() => setMessage(''), 10000);
    }
  };

  const handleSetFiles = (files) => {
    setMessage('');
    setFiles(files);

    if (isPayloadTooBig(files)) {
      setMessage('Files are too large: 10MB');
    } else {
      let names = [];
      for (const file of files) {
        names.push(file.name);
      }
      setMessage(`Files: ${names.join(' ,')}`);
    }
  };

  const isPayloadTooBig = (files) => {
    let totalSize = 0;
    for (const file of files) {
      totalSize += file.size;
    }

    return totalSize >= 10485760;
  };

  const deleteFile = async () => {
    setMessage('');
    if (fileToDelete === null) return;
    setLoading(true);

    try {
      const res = await fetch(
        `${BACKEND_URL}/documents/risk/${row.id}/${fileToDelete.id}/delete`,
        {
          method: 'GET',
          headers: headers,
        }
      );

      if (res.status === 200) {
        setMessage(`File "${fileToDelete.filename}" deleted.`);
        const res1 = await refetch({
          riskId: props?.row?.id,
        });
        const { data, loading, error } = res1;
        const documents = !error && !loading ? data.documents : null;

        setAttachments(documents);
      } else if (res.status === 404) {
        setMessage('File not found!');
      } else if (res.status === 401) {
        setMessage('Unauthorized !');
      } else if (res.status === 422) {
        setMessage('Unprocessable request ...');
      }
    } catch (err) {
      setMessage(err);
    } finally {
      setLoading(false);
      setTimeout(() => setMessage(''), 5000);
    }
  };

  const handleClickConfirmOpen = (fileToDelete) => {
    setFileToDelete(fileToDelete);
    setOpenConfirmDelete(true);
  };

  const handleConfirmClose = async () => {
    await deleteFile();
    setFileToDelete(null);
    setOpenConfirmDelete(false);
  };

  const handleCancelClose = () => {
    setFileToDelete(null);
    setOpenConfirmDelete(false);
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={attachmentsModalOpen}
        onClose={handleAttachmentsModalClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Attachments</DialogTitle>
        <DialogContent>
          <Grid container>
            <TableContainer component={Paper} style={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell width={'60%'} align="center">
                      Name
                    </TableCell>
                    <TableCell width={'15%'} align="center">
                      Type
                    </TableCell>
                    <TableCell width={'25%'} align="center">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attachments?.map((attachment) => (
                    <TableRow key={attachment?.filename}>
                      <TableCell>
                        {attachment?.filename ? (
                          <a
                            href={attachment.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {attachment.filename}
                          </a>
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell>{attachment?.type}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          color="default"
                          aria-label="Upload files"
                          component="span"
                          onClick={() => handleClickConfirmOpen(attachment)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <br />
            </Grid>
            <Grid item xs={6}>
              <input
                id="icon-button-file"
                style={{ display: 'none' }}
                className="App-input"
                type="file"
                multiple
                files={files}
                onChange={(e) => handleSetFiles(e.target.files)}
                accept=".jpg,.jpeg,.gif,.png,.doc,.docx,.pdf,.zip,.rar,.xlsx,.xls,.xml,.csv,.ppt,.pptx"
              />
              <label htmlFor="icon-button-file">
                <IconButton
                  color="primary"
                  aria-label="Upload files"
                  component="span"
                >
                  <AttachmentIcon />
                </IconButton>
              </label>
            </Grid>
            <Grid item xs={4}>
              {message === '' ? loading && 'Uploading...' : message}
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={uploadFile}
                disabled={files.length === 0 || isPayloadTooBig(files)}
              >
                Upload
              </Button>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleAttachmentsModalClose()}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openConfirmDelete}
        onClose={handleConfirmClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Warning !
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <b>Deleting the specified documents can't be undone.</b>&nbsp; Are
            you sure you want to permanently delete{' '}
            <b>"{fileToDelete ? fileToDelete.filename : ''}"</b> file ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancelClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmClose} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: 700,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
});

const AttachmentsModalWrap = withQuery(AttachmentsModal);

const AttachmentsModalWrapper = (props) => {
  const { loading, row } = props;
  return (
    <>{!loading && row && row.id && <AttachmentsModalWrap {...props} />}</>
  );
};

export default withStyles(styles)(AttachmentsModalWrapper);
