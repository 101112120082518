import React, { PropsWithChildren, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { RenderTree, IRiskGroup } from './types';
import { IWithGroups } from './withQuery';
import GroupDetails from './GroupDetails';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import AddDialog from './AddDialog';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 400,
  },
});

function transform(nodes: IRiskGroup[]): RenderTree {
  const root: IRiskGroup | null | undefined = nodes
    ? nodes.find(
        (n) => !n.hasOwnProperty('parentGroup') || n.parentGroup === null
      )
    : null;

  if (root) {
    const tree: RenderTree = {
      id: root.id.toString(),
      name: '',
      children: [],
    };

    addChildren(tree, nodes);

    tree.id = 'root';
    return tree;
  }

  return {
    id: 'root',
    name: '.',
    children: [],
  };
}

function addChildren(tree: RenderTree, nodes: IRiskGroup[]) {
  const treeChild: IRiskGroup | undefined = nodes.find(
    (elem) => elem.id.toString() === tree.id
  );

  if (treeChild) {
    tree.name = treeChild.name;

    if (treeChild.groupChildrenIds) {
      treeChild.groupChildrenIds.forEach((childId) => {
        const newChild: RenderTree = {
          id: childId.toString(),
          name: '',
          children: [],
        };

        tree.children?.push(newChild);
        addChildren(newChild, nodes);
      });
    }
  }
}

const RecursiveTreeView: React.FC<PropsWithChildren<IWithGroups>> = (props) => {
  const classes = useStyles();

  const [currentGroup, setCurrentGroup] = React.useState<IRiskGroup | null>(
    null
  );
  const [groups, setGroups] = React.useState<IRiskGroup[]>(props.groups);
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);

  const loadDetails = (groupId: string) => {
    if (groupId === 'root') {
      setCurrentGroup(
        groups.find(
          (n) => !n.hasOwnProperty('parentGroup') || n.parentGroup === null
        ) || null
      );
    } else {
      setCurrentGroup(groups.find((e) => e.id.toString() === groupId) || null);
    }
  };

  const renderTree = (nodes: RenderTree) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={nodes.name}
      onClick={() => loadDetails(nodes.id)}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  function handleAddDialogClose(groups: IRiskGroup[] | undefined) {
    if (groups) {
      setGroups(groups);
    }
    setAddDialogOpen(false);
  }

  function handleUpdateRiskGroups(groups: IRiskGroup[] | null) {
    if (groups) {
      setGroups(groups);

      if (currentGroup?.id) {
        const updatedGroup = groups.find((g) => g.id === currentGroup.id);

        if (updatedGroup) {
          setCurrentGroup(updatedGroup);
        }
      }
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: 5 }}
          onClick={() => setAddDialogOpen(true)}
        >
          New
        </Button>
      </Grid>
      <Grid item xs={4}>
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpanded={['root']}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {renderTree(transform(groups))}
        </TreeView>
      </Grid>
      <Grid item xs={8}>
        {currentGroup ? (
          <GroupDetails
            groups={groups}
            users={props.users}
            handleUpdateRiskGroups={handleUpdateRiskGroups}
            group={currentGroup}
          />
        ) : (
          ''
        )}
      </Grid>
      <AddDialog
        open={addDialogOpen}
        groups={groups}
        users={props.users}
        handleAddDialogClose={handleAddDialogClose}
      />
    </Grid>
  );
};

export default RecursiveTreeView;
