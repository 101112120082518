import FilterRenderer from './filterRenderer';
import Select from '@material-ui/core/Select';
import {
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  TextField,
} from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const ITEM_HEIGHT = 64;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getHeaderRenderer = (key, dictionaries, setFilters) => {
  const {
    categoriesDict,
    subCategoriesDict,
    overcostsDict,
    overtimesDict,
    impactsDict,
    probabilitiesDict,
    prioritiesDict,
    strategiesDict,
    statusesDict,
    phasesDict,
    rangesDict,
    globalitiesDict,
    users,
  } = dictionaries;

  switch (key) {
    case 'updatedAt':
      return (p) => (
        <FilterRenderer {...p}>
          {({ filters, ...rest }) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  style={{ width: 140 }}
                  format="yyyy-MM-dd"
                  margin="none"
                  id="updated_at-date-picker-from"
                  value={filters.updatedAt[0]}
                  onChange={(date) => {
                    setFilters({
                      ...filters,
                      updatedAt: [date, filters.updatedAt[1]],
                    });
                  }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  style={{ width: 140 }}
                  format="yyyy-MM-dd"
                  margin="none"
                  id="updated_at-date-picker-to"
                  value={filters.updatedAt[1]}
                  onChange={(date) => {
                    setFilters({
                      ...filters,
                      updatedAt: [filters.updatedAt[0], date],
                    });
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          )}
        </FilterRenderer>
      );
    case 'createdAt':
      return (p) => (
        <FilterRenderer {...p}>
          {({ filters, ...rest }) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  style={{ width: 140 }}
                  format="yyyy-MM-dd"
                  margin="none"
                  id="created_at-date-picker-from"
                  value={filters.createdAt[0]}
                  onChange={(date) => {
                    setFilters({
                      ...filters,
                      createdAt: [date, filters.createdAt[1]],
                    });
                  }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  style={{ width: 140 }}
                  format="yyyy-MM-dd"
                  margin="none"
                  id="created_at-date-picker-to"
                  value={filters.createdAt[1]}
                  onChange={(date) => {
                    setFilters({
                      ...filters,
                      createdAt: [filters.createdAt[0], date],
                    });
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          )}
        </FilterRenderer>
      );
    case 'range':
      return (p) => (
        <FilterRenderer {...p}>
          {({ filters, ...rest }) => (
            <Select
              multiple
              fullWidth={true}
              {...rest}
              value={filters.range}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  range:
                    typeof e.target.value === 'string'
                      ? e.target.value.split(',')
                      : e.target.value,
                });
              }}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {rangesDict.map((obj) => (
                <MenuItem
                  key={obj.value}
                  value={obj.value}
                  style={{ zIndex: 999 }}
                >
                  <Checkbox checked={filters.range?.indexOf(obj.value) > -1} />
                  <ListItemText primary={obj.value} />
                </MenuItem>
              ))}
            </Select>
          )}
        </FilterRenderer>
      );
    case 'globality':
      return (p) => (
        <FilterRenderer {...p}>
          {({ filters, ...rest }) => (
            <Select
              multiple
              fullWidth={true}
              {...rest}
              value={filters.globality}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  globality:
                    typeof e.target.value === 'string'
                      ? e.target.value.split(',')
                      : e.target.value,
                });
              }}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {globalitiesDict.map((obj) => (
                <MenuItem
                  key={obj.value}
                  value={obj.value}
                  style={{ zIndex: 999 }}
                >
                  <Checkbox
                    checked={filters.globality?.indexOf(obj.value) > -1}
                  />
                  <ListItemText primary={obj.value} />
                </MenuItem>
              ))}
            </Select>
          )}
        </FilterRenderer>
      );
    case 'strategy':
      return (p) => (
        <FilterRenderer {...p}>
          {({ filters, ...rest }) => (
            <Select
              multiple
              fullWidth={true}
              {...rest}
              value={filters.strategy}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  strategy:
                    typeof e.target.value === 'string'
                      ? e.target.value.split(',')
                      : e.target.value,
                });
              }}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {strategiesDict.map((obj) => (
                <MenuItem
                  key={obj.value}
                  value={obj.value}
                  style={{ zIndex: 999 }}
                >
                  <Checkbox
                    checked={filters.strategy?.indexOf(obj.value) > -1}
                  />
                  <ListItemText primary={obj.value} />
                </MenuItem>
              ))}
            </Select>
          )}
        </FilterRenderer>
      );
    case 'probability':
      return (p) => (
        <FilterRenderer {...p}>
          {({ filters, ...rest }) => (
            <Select
              fullWidth={true}
              {...rest}
              value={filters.probability}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  probability: e.target.value,
                });
              }}
              MenuProps={MenuProps}
            >
              <MenuItem key={`probability_all`} value={'All'}>
                All
              </MenuItem>
              {probabilitiesDict.map((obj) => (
                <MenuItem key={`probability_${obj.label}`} value={obj.label}>
                  {obj.label}
                </MenuItem>
              ))}
            </Select>
          )}
        </FilterRenderer>
      );
    case 'impact':
      return (p) => (
        <FilterRenderer {...p}>
          {({ filters, ...rest }) => (
            <Select
              multiple
              fullWidth={true}
              {...rest}
              value={filters.impact}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  impact:
                    typeof e.target.value === 'string'
                      ? e.target.value.split(',')
                      : e.target.value,
                });
              }}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {impactsDict.map((obj) => (
                <MenuItem
                  key={obj.value}
                  value={obj.value}
                  style={{ zIndex: 999 }}
                >
                  <Checkbox checked={filters.impact?.indexOf(obj.value) > -1} />
                  <ListItemText primary={obj.value} />
                </MenuItem>
              ))}
            </Select>
          )}
        </FilterRenderer>
      );
    case 'overtime':
      return (p) => (
        <FilterRenderer {...p}>
          {({ filters, ...rest }) => (
            <Select
              fullWidth={true}
              {...rest}
              value={filters.overtime}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  overtime: e.target.value,
                });
              }}
              MenuProps={MenuProps}
            >
              <MenuItem key={`overtime_all`} value={'All'}>
                All
              </MenuItem>
              {overtimesDict.map((obj) => (
                <MenuItem key={`overtime_${obj.label}`} value={obj.label}>
                  {obj.label}
                </MenuItem>
              ))}
            </Select>
          )}
        </FilterRenderer>
      );
    case 'overcost':
      return (p) => (
        <FilterRenderer {...p}>
          {({ filters, ...rest }) => (
            <Select
              fullWidth={true}
              {...rest}
              value={filters.overcost}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  overcost: e.target.value,
                });
              }}
              MenuProps={MenuProps}
            >
              <MenuItem key={'overcost_all'} value={'All'}>
                All
              </MenuItem>
              {overcostsDict.map((obj) => (
                <MenuItem key={`overcost_${obj.label}`} value={obj.label}>
                  {obj.label}
                </MenuItem>
              ))}
            </Select>
          )}
        </FilterRenderer>
      );
    case 'subcategory':
      return (p) => (
        <FilterRenderer {...p}>
          {({ filters, ...rest }) => (
            <Select
              multiple
              fullWidth={true}
              {...rest}
              value={filters.subcategory}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  subcategory:
                    typeof e.target.value === 'string'
                      ? e.target.value.split(',')
                      : e.target.value,
                });
              }}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {subCategoriesDict.map((obj) => (
                <MenuItem
                  key={`subcategory_${obj.value}`}
                  value={obj.value}
                  style={{ zIndex: 999 }}
                >
                  <Checkbox
                    checked={filters.subcategory?.indexOf(obj.value) > -1}
                  />
                  <ListItemText primary={obj.value} />
                </MenuItem>
              ))}
            </Select>
          )}
        </FilterRenderer>
      );
    case 'category':
      return (p) => (
        <FilterRenderer {...p}>
          {({ filters, ...rest }) => (
            <Select
              multiple
              fullWidth={true}
              {...rest}
              value={filters.category}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  category:
                    typeof e.target.value === 'string'
                      ? e.target.value.split(',')
                      : e.target.value,
                });
              }}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {categoriesDict.map((obj) => (
                <MenuItem
                  key={`category_${obj.value}`}
                  value={obj.value}
                  style={{ zIndex: 999 }}
                >
                  <Checkbox
                    checked={filters.category?.indexOf(obj.value) > -1}
                  />
                  <ListItemText primary={obj.value} />
                </MenuItem>
              ))}
            </Select>
          )}
        </FilterRenderer>
      );
    case 'phase':
      return (p) => (
        <FilterRenderer {...p}>
          {({ filters, ...rest }) => (
            <Select
              multiple
              fullWidth={true}
              {...rest}
              value={filters.phase}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  phase:
                    typeof e.target.value === 'string'
                      ? e.target.value.split(',')
                      : e.target.value,
                });
              }}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {phasesDict.map((obj) => (
                <MenuItem
                  key={obj.value}
                  value={obj.value}
                  style={{ zIndex: 999 }}
                >
                  <Checkbox checked={filters.phase?.indexOf(obj.value) > -1} />
                  <ListItemText primary={obj.value} />
                </MenuItem>
              ))}
            </Select>
          )}
        </FilterRenderer>
      );
    case 'planOwner':
      return (p) => (
        <FilterRenderer {...p}>
          {({ filters, ...rest }) => (
            <Select
              fullWidth={true}
              {...rest}
              value={filters.planOwner}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  planOwner: e.target.value,
                });
              }}
              MenuProps={MenuProps}
            >
              <MenuItem value={'All'}>All</MenuItem>
              {users.map((obj) => (
                <MenuItem value={obj.name}>{obj.name}</MenuItem>
              ))}
            </Select>
          )}
        </FilterRenderer>
      );
    case 'owner':
      return (p) => (
        <FilterRenderer {...p}>
          {({ filters, ...rest }) => (
            <Select
              fullWidth={true}
              {...rest}
              value={filters.owner}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  owner: e.target.value,
                });
              }}
              MenuProps={MenuProps}
            >
              <MenuItem value={'All'}>All</MenuItem>
              {users.map((obj) => (
                <MenuItem value={obj.name}>{obj.name}</MenuItem>
              ))}
            </Select>
          )}
        </FilterRenderer>
      );
    case 'status':
      return (p) => (
        <FilterRenderer {...p}>
          {({ filters, ...rest }) => (
            <Select
              multiple
              fullWidth={true}
              {...rest}
              value={filters.status}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  status:
                    typeof e.target.value === 'string'
                      ? e.target.value.split(',')
                      : e.target.value,
                });
              }}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {statusesDict.map((obj) => (
                <MenuItem
                  key={obj.value}
                  value={obj.value}
                  style={{ zIndex: 999 }}
                >
                  <Checkbox checked={filters.status?.indexOf(obj.value) > -1} />
                  <ListItemText primary={obj.value} />
                </MenuItem>
              ))}
            </Select>
          )}
        </FilterRenderer>
      );
    case 'priority':
      return (p) => (
        <FilterRenderer {...p}>
          {({ filters, ...rest }) => (
            <Select
              multiple
              fullWidth={true}
              {...rest}
              value={filters.priority}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  priority:
                    typeof e.target.value === 'string'
                      ? e.target.value.split(',')
                      : e.target.value,
                });
              }}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {prioritiesDict.map((obj) => (
                <MenuItem
                  key={obj.value}
                  value={obj.value}
                  style={{ zIndex: 999 }}
                >
                  <Checkbox
                    checked={filters.priority?.indexOf(obj.value) > -1}
                  />
                  <ListItemText primary={obj.value} />
                </MenuItem>
              ))}
            </Select>
          )}
        </FilterRenderer>
      );
    case 'report':
      return null;
    default:
      return (p) => (
        <FilterRenderer {...p}>
          {({ filters, ...rest }) => (
            <TextField
              {...rest}
              value={filters[key]}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  [key]: e.target.value,
                });
              }}
              variant="standard"
              size="small"
              fullWidth={true}
            />
          )}
        </FilterRenderer>
      );
  }
};

export default getHeaderRenderer;
