import gql from 'graphql-tag';

const UsersDisableMutation = gql`
  mutation UsersDisable($input: DisableUserMutationInput!) {
    adminUsersDisable(input: $input) {
      user {
        id
      }
      errors {
        name
        message
      }
    }
  }
`;

export default UsersDisableMutation;
