import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import mapConnection from '../../utils/connectionMapper';
import remapEntity from '../../utils/remapRiskTicketFields';

const RECORDS_QUERY = gql`
  query entities($filter: RiskEntityFilterInputObject!) {
    riskEvents(filter: $filter) {
      edges {
        node {
          id
          identifier
          specification
          title
          owner {
            name
          }
          category {
            label
          }
          subcategory {
            label
          }
          strategy {
            label
          }
          phase {
            label
          }
          status {
            label
          }
          priority {
            label
          }
          impact {
            label
          }
          riskGroup {
            name
          }
          probability
          overtime
          overcost
        }
      }
    }
  }
`;

const withQuery =
  (WrappedComponent) =>
  ({ ids, ...rest }) => {
    const { loading, error, data } = useQuery(RECORDS_QUERY, {
      variables: {
        filter: {
          ids: ids,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (error) return <p>QUERY ERROR</p>;

    let records = [];
    if (data) {
      records = mapConnection(data.riskEntities);
      records = records.map((e) => remapEntity(e));
    }

    return (
      <WrappedComponent
        records={loading || records === null ? [] : records}
        loading={loading}
        {...rest}
      />
    );
  };

export default withQuery;
