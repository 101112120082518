const dictionaryMapper = (dictionaries, type, key = 'label') => {
  if (!dictionaries) {
    return undefined;
  }
  return dictionaries
    .filter((dict) => dict.type === type)
    .sort((a, b) => {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    })
    .map((d) => {
      return {
        id: d.id,
        value: d.label,
        context: d.contextName,
        active: d.active,
        level: d.level,
        description: d.description,
      };
    });
};

export default dictionaryMapper;
