import React, { PropsWithChildren } from 'react';
import withQuery, { IWithMessages } from './withQuery';
import Spinner from '../UI/Spinner';
import MessagesTable from './MessagesTable';

const MessagesPage: React.FC<PropsWithChildren<IWithMessages>> = (props) => {
  const { rows, loading } = props;
  return (
    <div>{loading ? <Spinner /> : rows && <MessagesTable rows={rows} />}</div>
  );
};

export default withQuery(MessagesPage);
