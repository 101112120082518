import { SidebarItemsType } from '../../types/sidebar';
import {
  BookOpen,
  Grid,
  List,
  Sliders,
  Users,
  MessageCircle,
  Book,
  User,
  Folder,
  Star,
} from 'react-feather';

const riskRegisterSection = [
  {
    href: '/',
    icon: Sliders,
    title: 'Dashboards',
    // badge: '5',
  },
  {
    href: '/risks/entities',
    icon: List,
    title: 'Risk register',
  },
  // eslint-disable-next-line prettier/prettier
] as SidebarItemsType[];

const docsSection = [
  {
    href: '/docs/',
    icon: BookOpen,
    title: 'Manual',
  },
  // eslint-disable-next-line prettier/prettier
] as SidebarItemsType[];

const reportSection = [
  {
    href: '/analytics/summary',
    icon: Grid,
    title: 'Summary',
  },
] as SidebarItemsType[];

const settingsSection = [
  {
    href: '/settings/profile',
    icon: User,
    title: 'Profile',
  },
  {
    href: '/settings/messages',
    icon: MessageCircle,
    title: 'Messages',
  },
] as SidebarItemsType[];

const adminSection = [
  {
    href: '/admin/dictionaries',
    icon: Book,
    title: 'Dictionary',
  },
  {
    href: '/admin/groups',
    icon: Folder,
    title: 'Groups',
  },
  {
    href: '/admin/users',
    icon: Users,
    title: 'Users',
  },
  {
    href: '/admin/new-report',
    icon: Star,
    title: 'New report',
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: 'Risk events',
    pages: riskRegisterSection,
  },
  {
    title: 'Report',
    pages: reportSection,
  },
  {
    title: 'Settings',
    pages: settingsSection,
  },
  {
    title: 'Administration',
    pages: adminSection,
  },
  {
    title: 'Documentation',
    pages: docsSection,
  },
];

export default navItems;
