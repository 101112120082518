import React from 'react';
import { Card } from 'react-bootstrap';

import ResetPasswordConfirmation from '../../components/auth/ResetPasswordConfirmation';

const ResetPasswordConfirmationPage = () => (
  <React.Fragment>
    <div className="text-center mt-4">
      <h1 className="h2">Reset password</h1>
      <p className="lead">Enter the verification code and new password.</p>
    </div>

    <Card>
      <Card.Body>
        <div className="m-sm-3">
          <ResetPasswordConfirmation />
        </div>
      </Card.Body>
    </Card>
  </React.Fragment>
);

export default ResetPasswordConfirmationPage;
