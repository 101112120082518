import { SelectColumn } from 'react-data-grid';
import {
  OvercostFormatter,
  OvertimeFormatter,
  PriorityFormatter,
  ProbabilityFormatter,
  LongTextFormatter,
} from '../formatters';
import 'date-fns';
import moment from 'moment';
import getHeaderRenderer from './headerRenderers';

const headerCellClass = 'filter-cell';
function DatetimeFormatter({ datetime }) {
  return <>{moment(datetime).format('yyyy-MM-DD / HH:mm')}</>;
}

const parseWidth = (value) => {
  try {
    return parseInt(value);
  } catch {
    return undefined;
  }
};

const columns = ({
  riskColumns,
  setFilters,
  users,
  categoriesDictionary,
  subCategoriesDictionary,
  overcostDictionary,
  overtimeDictionary,
  impactDictionary,
  probabilitiesDictionary,
  prioritiesDictionary,
  strategiesDictionary,
  statusesDictionary,
  phasesDictionary,
  rangesDictionary,
  globalitiesDictionary,
}) => {
  const categoriesDict = categoriesDictionary.map((d) => {
    return { value: d.value, label: d.value };
  });
  const subCategoriesDict = subCategoriesDictionary.map((d) => {
    return { value: d.value, label: d.value };
  });
  const overcostsDict = overcostDictionary
    .filter((d) => d.active)
    .map((d) => {
      return { value: d.value, label: d.value };
    });
  const overtimesDict = overtimeDictionary
    .filter((d) => d.active)
    .map((d) => {
      return { value: d.value, label: d.value };
    });
  const impactsDict = impactDictionary.map((d) => {
    return { value: d.value, label: d.value };
  });
  const probabilitiesDict = probabilitiesDictionary.map((d) => {
    return { value: d.value, label: d.value };
  });
  const prioritiesDict = prioritiesDictionary.map((d) => {
    return { value: d.value, label: d.value };
  });
  const strategiesDict = strategiesDictionary.map((d) => {
    return { value: d.value, label: d.value };
  });
  const statusesDict = statusesDictionary.map((d) => {
    return { value: d.value, label: d.value };
  });
  const phasesDict = phasesDictionary.map((d) => {
    return { value: d.value, label: d.value };
  });
  const rangesDict = rangesDictionary.map((d) => {
    return { value: d.value, label: d.value };
  });
  const globalitiesDict = globalitiesDictionary.map((d) => {
    return { value: d.value, label: d.value };
  });
  const dictionaries = {
    categoriesDict,
    subCategoriesDict,
    overcostsDict,
    overtimesDict,
    impactsDict,
    probabilitiesDict,
    prioritiesDict,
    strategiesDict,
    statusesDict,
    phasesDict,
    rangesDict,
    globalitiesDict,
    users,
  };

  const getFormatter = (format, key) => {
    if (format === 'LongText') {
      return ({ row }) => <LongTextFormatter value={row[key]} />;
    } else if (format === 'Priority') {
      return ({ row }) => (
        <PriorityFormatter
          status={row.status}
          priorityId={row.priorityId}
          value={row[key]}
        />
      );
    } else if (format === 'Overcost') {
      return ({ row }) => <OvercostFormatter value={row[key]} row={row} />;
    } else if (format === 'Overtime') {
      return ({ row }) => <OvertimeFormatter value={row[key]} row={row} />;
    } else if (format === 'Probability') {
      return ({ row }) => <ProbabilityFormatter value={row[key]} />;
    } else if (format === 'Datetime') {
      return ({ row }) => <DatetimeFormatter datetime={row[key]} />;
    } else {
      return null;
    }
  };

  const cols = [SelectColumn];

  riskColumns
    .filter((c) => c.visible)
    .forEach((col) => {
      cols.push({
        name: col.name,
        key: col.key,
        headerCellClass,
        width: parseWidth(col.width),
        notMovable: col.notMovable,
        resizable: col.resizable,
        sortable: col.sortable,
        formatter: getFormatter(col.format, col.key),
        headerRenderer: getHeaderRenderer(col.key, dictionaries, setFilters),
      });
    });

  return cols;
};

export default columns;
