import React, { useContext } from 'react';
import { Form, Card, Button, Row, Col } from 'react-bootstrap';
import NotyfContext from '../../contexts/NotyfContext';
import {
  displayErrorMessage,
  displaySuccessMessage,
} from '../../utils/displayMessage';
import { BACKEND_URL } from '../../utils/config';
import useAuth from '../../hooks/useAuth';

const isPayloadTooBig = (files) => {
  let totalSize = 0;
  for (const file of files) {
    totalSize += file.size;
  }

  return totalSize >= 10485760;
};

const UserAvatarForm = ({ userProfile, refetch_user }) => {
  const [files, setFiles] = React.useState([]);
  const notyf = useContext(NotyfContext);
  const { token } = useAuth();

  const headers = {
    authorization: `Bearer ${token}`,
  };

  const uploadFile = async () => {
    if (files.length === 0 || isPayloadTooBig(files)) return;

    try {
      var formData = new FormData();
      for (const file of files) {
        formData.append('files[]', file, file.name);
      }

      const res = await fetch(
        `${BACKEND_URL}/documents/user/${userProfile.id}/upload`,
        {
          method: 'POST',
          body: formData,
          headers: headers,
        }
      );

      if (res.status === 200) {
        displaySuccessMessage(notyf, 'File Upload Success!');
        setFiles([]);

        refetch_user({});
      } else {
        res.json().then((body) => {
          displayErrorMessage(
            notyf,
            `Error[${res.status}]: ${body?.errors.join(' ,')}`
          );
        });
      }
    } catch (err) {
      displayErrorMessage(notyf, err.message);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Form>
            <Card>
              <Card.Header>
                <Card.Title>Avatar</Card.Title>
                <h6 className="card-subtitle text-muted">
                  User's avatar image
                </h6>
              </Card.Header>
              <Card.Body>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="file"
                    name="file"
                    multiple={false}
                    file={files}
                    accept=".jpg,.jpeg,.gif,.png"
                    onChange={(e) => setFiles(e.target.files)}
                    acc
                  />
                  <Form.Text className="text-muted">Avatar image</Form.Text>
                </Form.Group>
              </Card.Body>
              <Card.Footer>
                <Button
                  onClick={uploadFile}
                  disabled={files.length === 0 || isPayloadTooBig(files)}
                >
                  Upload
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        </Col>
        <Col>
          <Card>
            <Card.Img
              width="100%"
              src={userProfile.avatarUrl}
              alt="User's avatar"
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default UserAvatarForm;
