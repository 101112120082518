import gql from 'graphql-tag';

const RiskGroupsCreateMutation = gql`
  mutation RiskGroupsCreate($input: CreateRiskGroupMutationInput!) {
    riskGroupsCreate(input: $input) {
      riskGroups {
        id
        name
        leader {
          id
          email
          name
        }
        parentGroup {
          id
          name
        }
        abbreviation
        users {
          id
          email
          name
        }
        groupChildrenIds
      }
      errors {
        name
        message
      }
    }
  }
`;

export default RiskGroupsCreateMutation;
