import React, { PropsWithChildren } from 'react';
import { IWithReport } from '../SummaryTable/withQuery';
import SummaryTable from './SummaryTable';
import withQuery from './withQuery';

const SummaryTablePage: React.FC<PropsWithChildren<IWithReport>> = (props) => {
  return <SummaryTable {...props} />;
};

export default withQuery(SummaryTablePage);
