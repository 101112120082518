export const BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || 'http://localhost:3000';
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || '';
export const ENVIRONMENT_NAME =
  process.env.REACT_APP_ENVIRONMENT_NAME || 'development';

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};
