import { SET_ALL_USERS, SET_CURRENT_USER } from '../actions/action-types';

const usersReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: payload.user,
      };
    case SET_ALL_USERS:
      return {
        ...state,
        allUsers: payload.users,
      };
    default:
      return state;
  }
};

export default usersReducer;
