import { SHOW_MESSAGE, HIDE_MESSAGE } from '../actions/action-types';

const initialState = {
  content: '',
  visibleMessage: false,
};

const messageRecuder = (state = initialState, action) => {
  const { type, message } = action;

  switch (type) {
    case SHOW_MESSAGE:
      return {
        ...state,
        visibleMessage: true,
        content: message.content,
      };

    case HIDE_MESSAGE:
      return {
        ...state,
        visibleMessage: false,
        content: '',
      };

    default:
      return state;
  }
};

export default messageRecuder;
