import React, { Component, useContext } from 'react';
import { DialogContent, DialogTitle } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import RiskGroupsUpdateMutation from '../../mutations/RiskGroupsUpdateMutation';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { useMutation } from '@apollo/client';
import NotyfContext from '../../contexts/NotyfContext';
import {
  displayErrorMessage,
  displaySuccessMessage,
} from '../../utils/displayMessage';

class EditDialog extends Component {
  handleAdd = async () => {
    const { saveGroupMutHandler, handleDialogClose, notyf } = this.props;

    try {
      this.setState({ ...this.state, loading: true });
      let result = await saveGroupMutHandler({
        variables: {
          input: {
            groupId: parseInt(this.state.group.id),
            name: this.state.group.name,
            abbreviation: this.state.group.abbreviation,
            leaderId: this.state.group.leader.id,
            number: parseInt(this.state.group.number),
            parentGroupId: parseInt(this.state.group.parentGroup.id),
          },
        },
      });

      const updateResult = result.data.riskGroupsUpdate;
      const errors = updateResult.errors;

      if (
        result != null &&
        updateResult != null &&
        errors != null &&
        errors.length > 0
      ) {
        displayErrorMessage(notyf, errors[0].message);
      } else {
        handleDialogClose(result.data.riskGroupsUpdate.riskGroups);
        displaySuccessMessage(
          notyf,
          `Group ${this.state.group.name} has been updated!`
        );
      }
    } catch (err) {
      displayErrorMessage(notyf, err.message);
    } finally {
      this.setState({ ...this.state, loading: false });
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      group: props.group,
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.group.id !== this.props.group.id) {
      this.setState({ ...this.state, group: this.props.group });
    }
  }

  render() {
    const { open, handleDialogClose, groups, users } = this.props;

    return (
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Edit risk group</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl style={{ width: 300 }}>
                <TextField
                  id="standard-multiline-flexible"
                  label="Name"
                  value={this.state.group ? this.state.group.name : null}
                  multiline
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      group: {
                        ...this.state.group,
                        name: event.target.value,
                      },
                    });
                  }}
                  style={{ width: 500 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl style={{ width: 300 }}>
                <TextField
                  id="standard-multiline-flexible"
                  label="Abbreviation"
                  value={
                    this.state.group ? this.state.group.abbreviation : null
                  }
                  multiline
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      group: {
                        ...this.state.group,
                        abbreviation: event.target.value,
                      },
                    });
                  }}
                  style={{ width: 500 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl style={{ width: 300 }}>
                <InputLabel id="demo-simple-select-label">
                  Group Leader
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.group ? this.state.group.leader.name : null}
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      group: {
                        ...this.state.group,
                        leader: users.find(
                          (u) => u.name === event.target.value
                        ),
                      },
                    });
                  }}
                >
                  {users &&
                    users.map((user) => (
                      <MenuItem value={user.name}>{user.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <FormControl style={{ width: 300 }}>
                <InputLabel id="demo-simple-select-label">
                  Parent group
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    this.state.group && this.state.group.parentGroup
                      ? this.state.group.parentGroup.name
                      : null
                  }
                  onChange={(event) =>
                    this.setState({
                      ...this.state,
                      group: {
                        ...this.state.group,
                        parentGroup: groups.find(
                          (g) => g.name === event.target.value
                        ),
                      },
                    })
                  }
                >
                  {groups &&
                    groups.map((group) => (
                      <MenuItem key={group.id} value={group.name}>
                        {group.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl style={{ width: 300 }}>
                <TextField
                  id="standard-multiline-flexible"
                  label="Group number"
                  value={this.state.group ? this.state.group.number : null}
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      group: {
                        ...this.state.group,
                        number: event.target.value,
                      },
                    });
                  }}
                  number
                  style={{ width: 200 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={this.state.loading}
                variant="contained"
                color="secondary"
                onClick={() => handleDialogClose(null)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} align="right">
              <Button
                disabled={this.state.loading}
                variant="contained"
                color="primary"
                onClick={() => this.handleAdd()}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const EditDialogWrapper = (props) => {
  const [saveMutation] = useMutation(RiskGroupsUpdateMutation);
  const notyf = useContext(NotyfContext);

  return (
    <EditDialog {...props} notyf={notyf} saveGroupMutHandler={saveMutation} />
  );
};

export default EditDialogWrapper;
