import React, { useContext, useState } from 'react';
import { Button, Col, Modal, Row, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Select from 'react-select';
import CreateRiskEntityMutation from '../../mutations/CreateRiskEntityMutation';
import { useMutation } from '@apollo/client';
import store from '../../store/store';
import remapEntity from '../../utils/remapRiskTicketFields';
import NotyfContext from '../../contexts/NotyfContext';
import {
  displayErrorMessage,
  displaySuccessMessage,
} from '../../utils/displayMessage';
import { Button as MButton } from '@material-ui/core';

const schema = Yup.object().shape({
  specification: Yup.string().required().min(5),
  riskGroup: Yup.string().required(),
  owner: Yup.string().required(),
});

function findGroup(name, groups) {
  let result = null;
  groups.forEach((group) => {
    if (group.name === name) {
      result = group;
    }
  });

  return result;
}

const NewRiskModalForm = ({ createRiskEntityMutation, openModalHandler }) => {
  const { currentUser } = store.getState().users;
  const notyf = useContext(NotyfContext);

  const groups = currentUser.myGroups;

  const saveHandler = async (values) => {
    try {
      let result = await createRiskEntityMutation({
        variables: {
          input: values,
        },
      });

      if (result.errors != null && result.errors.length > 0) {
        console.log(result.errors[0].message);
        openModalHandler();
      } else if (
        result != null &&
        result.data.riskEventsCreate != null &&
        result.data.riskEventsCreate.errors != null &&
        result.data.riskEventsCreate.errors.length > 0
      ) {
        openModalHandler();
        displayErrorMessage(
          notyf,
          result.data.riskEventsCreate.errors[0].message
        );
      } else {
        const newEvent = remapEntity(result.data.riskEventsCreate.riskEvent);
        openModalHandler(newEvent);
        displaySuccessMessage(
          notyf,
          `New risk event has just been created: ${newEvent.identifier}`
        );
      }
    } catch (err) {
      displayErrorMessage(notyf, err.message);
      openModalHandler();
    }
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={saveHandler}
      initialValues={{
        specification: '',
        riskGroup: '',
        owner: '',
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        setFieldValue,
      }) => {
        const groupsOptions = groups.map((g) => {
          return { label: g.name, value: g.name };
        });
        const selectedGroup = findGroup(
          values.riskGroup && values.riskGroup,
          groups
        );

        const groupUsersOptions = selectedGroup
          ? selectedGroup.users.map((user) => {
              return { label: user.name, value: user.name };
            })
          : [];

        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="vh01">
                <Form.Label>Specification</Form.Label>
                <Form.Control
                  style={{ width: '100%' }}
                  rows={5}
                  as="textarea"
                  name="specification"
                  value={values.specification}
                  onChange={handleChange}
                  isInvalid={!!errors.specification}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.specification}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="vh02">
                <Form.Label>Group</Form.Label>
                <Select
                  type="text"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={groupsOptions}
                  name="riskGroup"
                  value={groupsOptions.find(
                    (f) => values.riskGroup === f.value
                  )}
                  onChange={(option) =>
                    setFieldValue('riskGroup', option.value)
                  }
                />
                {
                  <div
                    style={{ color: 'red', marginTop: '.5rem', fontSize: 10 }}
                  >
                    {errors.riskGroup}
                  </div>
                }
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="vh03">
                <Form.Label>Risk owner</Form.Label>
                <Select
                  type="text"
                  name="owner"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={groupUsersOptions}
                  value={groupUsersOptions.find(
                    (f) => values.owner === f.value
                  )}
                  onChange={(option) => setFieldValue('owner', option.value)}
                  isInvalid={!!errors.owner}
                />
                <div style={{ color: 'red', marginTop: '.5rem', fontSize: 10 }}>
                  {errors.owner}
                </div>
              </Form.Group>
            </Row>
            <Row className="m-1">
              <Button type="submit">Create</Button>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

const NewRiskModal = ({ createRiskEntityMutation, handleAddModalClose }) => {
  const index = 0;
  const size = 'sm';

  const [openModal, setOpenModal] = useState(false);

  const toggle = () => {
    setOpenModal(!openModal);
  };

  const openModalHandler = (newRisk) => {
    handleAddModalClose(newRisk);
    toggle();
  };

  return (
    <React.Fragment key={index}>
      <MButton
        variant="contained"
        color="primary"
        className="me-1"
        onClick={() => toggle()}
      >
        Add
      </MButton>
      <Modal show={openModal} onHide={() => toggle()} size={size}>
        <Modal.Header closeButton>Create new risk</Modal.Header>
        <Modal.Body>
          <NewRiskModalForm
            createRiskEntityMutation={createRiskEntityMutation}
            openModalHandler={openModalHandler}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

const withMutation = (WrappedComponent) => (props) => {
  const [createRiskEntityMutation] = useMutation(CreateRiskEntityMutation);
  return (
    <WrappedComponent
      {...props}
      createRiskEntityMutation={createRiskEntityMutation}
    />
  );
};

export default withMutation(NewRiskModal);

/*
      <Button variant="primary" onClick={() => toggle()} className="me-1">
        Add
      </Button>
*/
