import React from 'react';
import Spinner from '../UI/Spinner';
import UsersTable from './UsersTable';
import withQuery from './withQuery';

const UsersPage = (props) => {
  const { loading, users, groups } = props;
  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        users && <UsersTable users={users} groups={groups} />
      )}
    </div>
  );
};

export default withQuery(UsersPage);
