import withQuery from './withQuery';
import Selector from './Selector';

const RiskEntitiesPage = (props) => {
  const FilterSelector = withQuery(Selector);

  return <FilterSelector {...props} />;
};

export default withQuery(RiskEntitiesPage);
