import gql from 'graphql-tag';

const CreateRiskEntityMutation = gql`
  mutation Create($input: CreateMutationInput!) {
    riskEventsCreate(input: $input) {
      riskEvent {
        id
        identifier
        title
        specification
        migrationScenario
        owner {
          name
        }
        planOwner {
          name
        }
        category {
          label
        }
        subcategory {
          label
        }
        strategy {
          label
        }
        phase {
          label
        }
        status {
          label
        }
        priority {
          label
        }
        impact {
          label
        }
        globality {
          label
        }
        range {
          label
        }
        reportingCycle {
          title
        }
        riskGroup {
          name
        }
        probability
        overtime
        overcost

        canWrite
      }

      errors {
        name
        message
      }
    }
  }
`;

export default CreateRiskEntityMutation;
