import React from 'react';
import PrioritiesChart from '../Analysis/Charts/PrioritiesChart';
import UserCard from '../Profile/UserCard';
import PriorityReportTable from '../Analysis/PriorityReportTable';
import {
  DEFAULT_GLOBALITY_FILTER,
  DEFAULT_PHASE_FILTER,
  DEFAULT_RANGE_FILTER,
  DEFAULT_STATUS_FILTER,
  DEFAULT_STATUS_FILTER_NO_CLOSED,
  DEFAULT_PRIORITY_FILTER,
} from '../../utils/consts';
import { Container, Row, Col } from 'react-bootstrap';

import { Card, InputGroup, Form } from 'react-bootstrap';

const DashboardPage = (props: any) => {
  const [closedStatus, setClosedStatus] = React.useState(true);

  const handleStatusChange = (event: any) => {
    setClosedStatus(event.target.checked);
  };

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <UserCard />
        <Row>
          <Col xl="4" lg="6" md="12" className="d-flex">
            <PrioritiesChart
              filters={{
                status: closedStatus
                  ? DEFAULT_STATUS_FILTER
                  : DEFAULT_STATUS_FILTER_NO_CLOSED,
                phase: DEFAULT_PHASE_FILTER,
                globality: DEFAULT_GLOBALITY_FILTER,
                range: DEFAULT_RANGE_FILTER,
                priority: DEFAULT_PRIORITY_FILTER,
              }}
            />
          </Col>
          <Col xl="6" lg="12" className="d-flex" style={{ minWidth: 700 }}>
            <PriorityReportTable
              filters={{
                status: closedStatus
                  ? DEFAULT_STATUS_FILTER
                  : DEFAULT_STATUS_FILTER_NO_CLOSED,
                phase: DEFAULT_PHASE_FILTER,
                globality: DEFAULT_GLOBALITY_FILTER,
                range: DEFAULT_RANGE_FILTER,
                priority: DEFAULT_PRIORITY_FILTER,
              }}
            />
          </Col>
          <Col lg="2" className="d-flex">
            <Card>
              <Card.Header>
                <Card.Title className="mb-0">Status</Card.Title>
              </Card.Header>
              <Card.Body>
                <InputGroup className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Check
                      label="New, Active, Residual"
                      checked={true}
                      type="checkbox"
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Check
                      label="Closed"
                      type="checkbox"
                      checked={closedStatus}
                      onChange={handleStatusChange}
                      name="status_closed"
                    />
                  </Form.Group>
                </InputGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default DashboardPage;
