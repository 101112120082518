import React from 'react';
import Spinner from '../UI/Spinner';
import { useQuery, gql } from '@apollo/client';

import { Col, Card, Row, Badge } from 'react-bootstrap';
import illustration from '../../assets/img/illustrations/customer-support.png';
import { DollarSign, Clock } from 'react-feather';

const USER_QUERY = gql`
  query myUser {
    currentUser {
      id
      email
      firstName
      lastName
      name
      phone
      position
      abbreviation
      office
      createdAt
    }
  }
`;

function withQuery(WrappedComponent) {
  return (props) => {
    const { loading, error, data } = useQuery(USER_QUERY, {
      variables: {},
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    });

    if (error) return <p>ERROR</p>;
    const user = !error && !loading ? data.currentUser : null;

    return <WrappedComponent {...props} user={user} loading={loading} />;
  };
}

const UserCard = (props) => {
  const { user } = props;

  return (
    <Row>
      <Col md="6" xl className="d-flex">
        <Card className="illustration flex-fill">
          <Card.Body className="p-0 d-flex flex-fill">
            <Row className="g-0 w-100">
              <Col xs="6">
                <div className="illustration-text p-3 m-1">
                  <h4 className="illustration-text">
                    {'Welcome back'}, {user.firstName}!
                  </h4>
                  <p className="mb-0">RMS Dashboard</p>
                </div>
              </Col>
              <Col xs={6} className="align-self-end text-end">
                <img
                  src={illustration}
                  alt="Customer Support"
                  className="img-fluid illustration-img"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h3 className="mb-2">$ 24.300</h3>
                <p className="mb-2">Total Earnings</p>
                <div className="mb-0">
                  <Badge bg="" className="badge-soft-success me-2">
                    +5.35%
                  </Badge>
                  <span className="text-muted">Since last week</span>
                </div>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat">
                  <DollarSign className="align-middle text-success" />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body>
            <Row>
              <Col className="mt-0">
                <h5 className="card-title">Real-Time</h5>
              </Col>
              <Col xs="auto">
                <div className="stat stat-sm">
                  <Clock className="align-middle text-success" />
                </div>
              </Col>
            </Row>
            <span className="h1 d-inline-block mt-1 mb-4">1.856</span>
            <div className="mb-0">
              <Badge bg="" className="badge-soft-success me-2">
                +2.25%
              </Badge>
              <span className="text-muted">Since last week</span>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

const Profile = (props) => {
  const { loading, user } = props;
  return <div>{loading ? <Spinner /> : user && <UserCard user={user} />}</div>;
};

export default withQuery(Profile);
