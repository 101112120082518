export const phaseToTimeUnits = (phase) => {
  if (phase === 'OPERATION') {
    return '[bhr]';
  } else {
    // if (phase === "CONSTRUCTION") {
    return '[h]';
  }
  // } else if (row.phase === 'PROJECT') {
  //   units = " [MTH]";
  //}
};
