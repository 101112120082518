import gql from 'graphql-tag';

const CommentsAddMutation = gql`
  mutation add($input: CreateCommentMutationInput!) {
    commentsCreate(input: $input) {
      comment {
        id
        content
        commentType
        user {
          name
        }
        createdAt
      }

      errors {
        name
        message
      }
    }
  }
`;

export default CommentsAddMutation;
