import React, { useContext, useState } from 'react';
import { Button, Col, Row, Form, Container, Card } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import UsersProfileUpdateMutation from '../../mutations/UsersProfileUpdateMutation';
import NotyfContext from '../../contexts/NotyfContext';
import {
  displayErrorMessage,
  displaySuccessMessage,
} from '../../utils/displayMessage';
import gql from 'graphql-tag';
import Spinner from '../UI/Spinner';
import UsersChangePasswordMutation from '../../mutations/UsersChangePasswordMutation';
import UserAvatarForm from './UserAvatarForm';

const USER_QUERY = gql`
  query user {
    currentUser {
      id
      email
      firstName
      lastName
      phone
      position
      abbreviation
      office
      leaderGroups
      riskGroup
      role
      cognitoStatus
      avatarUrl
    }
  }
`;

const profileSchema = Yup.object().shape({
  email: Yup.string().required().email(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  phone: Yup.string(),
  position: Yup.string().nullable(),
  abbreviation: Yup.string().nullable(),
  office: Yup.string().nullable(),
});

const passSchema = Yup.object().shape({
  password: Yup.string().required().min(8),
  passwordConfirmation: Yup.string()
    .required()
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const UserProfileForm = ({
  updateUserProfileMutation,
  changePasswordMutation,
  userProfile,
  refetch_user,
}) => {
  const notyf = useContext(NotyfContext);
  const [isLoading, setIsLoading] = useState(false);

  const saveHandler = async (values) => {
    try {
      const { data, loading, error } = await updateUserProfileMutation({
        variables: {
          input: {
            id: values.id,
            firstName: values.firstName,
            lastName: values.lastName,
            position: values.position,
            abbreviation: values.abbreviation,
            phone: values.phone,
            office: values.office,
          },
        },
      });
      setIsLoading(loading);

      if (error) {
        displayErrorMessage(notyf, error.message);
      } else if (
        data.usersProfileUpdate.errors != null &&
        data.usersProfileUpdate.errors.length > 0
      ) {
        displayErrorMessage(notyf, data.usersProfileUpdate.errors[0].message);
      } else {
        displaySuccessMessage(notyf, 'Profile updated');
      }
    } catch (err) {
      displayErrorMessage(notyf, err.message);
    }
  };

  const changePasswordHandler = async (values) => {
    try {
      const { data, loading, error } = await changePasswordMutation({
        variables: {
          input: {
            id: values.id,
            password: values.password,
          },
        },
      });
      setIsLoading(loading);

      if (error) {
        displayErrorMessage(notyf, error.message);
      } else if (
        data.usersChangePassword.errors != null &&
        data.usersChangePassword.errors.length > 0
      ) {
        displayErrorMessage(notyf, data.usersChangePassword.errors[0].message);
      } else {
        displaySuccessMessage(notyf, 'Password has been changed');
      }
    } catch (err) {
      displayErrorMessage(notyf, err.message);
    }
  };

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">User profile</h1>
      <Formik
        validationSchema={profileSchema}
        onSubmit={saveHandler}
        initialValues={{
          ...userProfile,
        }}
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => {
          return (
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <Col lg="6" md="12">
                  <Card>
                    <Card.Header>
                      <Card.Title>Personal info</Card.Title>
                      <h6 className="card-subtitle text-muted">
                        User's personal info
                      </h6>
                    </Card.Header>
                    <Card.Body>
                      <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="vf01">
                          <Form.Label>ID</Form.Label>
                          <Form.Control
                            name="id"
                            value={values.id}
                            disabled
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="vf02">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            name="email"
                            disabled
                            value={values.email}
                          />
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="vf03">
                          <Form.Label>First name</Form.Label>
                          <Form.Control
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            isInvalid={!!errors.firstName}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            {errors.firstName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="vf04">
                          <Form.Label>Last name</Form.Label>
                          <Form.Control
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            isInvalid={!!errors.lastName}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            {errors.lastName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="vf05">
                          <Form.Label>Phone number</Form.Label>
                          <Form.Control
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                            isInvalid={!!errors.phone}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            {errors.phone}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="6" md="12">
                  <Card>
                    <Card.Header>
                      <Card.Title>Organizational</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="vf06">
                          <Form.Label>Office</Form.Label>
                          <Form.Control
                            name="office"
                            value={values.office}
                            onChange={handleChange}
                            isInvalid={!!errors.office}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            {errors.office}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="vf07">
                          <Form.Label>Abbreviation</Form.Label>
                          <Form.Control
                            name="abbreviation"
                            value={values.abbreviation}
                            onChange={handleChange}
                            isInvalid={!!errors.abbreviation}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            {errors.abbreviation}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="vf08">
                          <Form.Label>Position</Form.Label>
                          <Form.Control
                            name="position"
                            value={values.position}
                            onChange={handleChange}
                            isInvalid={!!errors.position}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            {errors.position}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="vf09">
                          <Form.Label>Member of group</Form.Label>
                          <Form.Control
                            name="riskGroup"
                            disabled
                            value={values.riskGroup}
                          />
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="vf10">
                          <Form.Label>Leader of group</Form.Label>
                          <Form.Control
                            name="leaderGroups"
                            disabled
                            value={values.leaderGroups}
                          />
                        </Form.Group>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="m-1 mb-4">
                <Button type="submit" disabled={isLoading}>
                  Save
                </Button>
              </Row>
            </Form>
          );
        }}
      </Formik>
      <Formik
        validationSchema={passSchema}
        onSubmit={changePasswordHandler}
        initialValues={{
          ...userProfile,
          password: '',
          passwordConfirmation: '',
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => {
          return (
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <Col lg="6" md="12">
                  <Card>
                    <Card.Header>
                      <Card.Title>Cognito</Card.Title>
                      <h6 className="card-subtitle text-muted">
                        Authentication service details
                      </h6>
                    </Card.Header>
                    <Card.Body>
                      <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="vg01">
                          <Form.Label>Role</Form.Label>
                          <Form.Control
                            name="role"
                            disabled
                            value={values.role}
                          />
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="vg02">
                          <Form.Label>Cognito status</Form.Label>
                          <Form.Control
                            name="cognitoStatus"
                            disabled
                            value={values.cognitoStatus}
                          />
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="vg03">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            name="password"
                            type="password"
                            value={values.password}
                            onChange={handleChange}
                            isInvalid={!!errors.password}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="vg04">
                          <Form.Label>Password confirmation</Form.Label>
                          <Form.Control
                            name="passwordConfirmation"
                            type="password"
                            value={values.passwordConfirmation}
                            onChange={handleChange}
                            isInvalid={!!errors.passwordConfirmation}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            {errors.passwordConfirmation}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                    </Card.Body>
                    <Card.Footer>
                      <Button type="submit" disabled={isLoading}>
                        Change password
                      </Button>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      <UserAvatarForm userProfile={userProfile} refetch_user={refetch_user} />
    </Container>
  );
};

const withMutation = (WrappedComponent) => (props) => {
  const [updateUserProfileMutation] = useMutation(UsersProfileUpdateMutation);
  const [changePasswordMutation] = useMutation(UsersChangePasswordMutation);
  return (
    <WrappedComponent
      {...props}
      updateUserProfileMutation={updateUserProfileMutation}
      changePasswordMutation={changePasswordMutation}
    />
  );
};

const withQuery = (WrappedComponent) => (props) => {
  const { loading, data, refetch } = useQuery(USER_QUERY);
  const user = data ? data.currentUser : null;

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        user && (
          <WrappedComponent
            {...props}
            loading={loading}
            userProfile={user}
            refetch_user={refetch}
          />
        )
      )}
    </>
  );
};

export default withQuery(withMutation(UserProfileForm));
