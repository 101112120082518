import React, { PropsWithChildren } from 'react';
import Spinner from '../UI/Spinner';
import RiskGroupsTree from './RiskGroupsTree';
import withQuery from './withQuery';
import { IWithGroups } from './withQuery';

const RiskGroupsPage: React.FC<PropsWithChildren<IWithGroups>> = (props) => {
  const { groups, users, loading } = props;
  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        groups && <RiskGroupsTree groups={groups} users={users} />
      )}
    </div>
  );
};

export default withQuery(RiskGroupsPage);
