import { SET_RISK_FILTERS } from '../actions/action-types';

const riskFiltersReducer = (
  state = {
    identifier: '',
    title: '',
    specification: '',
    migrationScenario: '',
    status: [],
    owner: 'All',
    category: [],
    subcategory: [],
    strategy: [],
    phase: [],
    priority: [],
    impact: [],
    globality: [],
    range: [],
    report: [],
    probability: 'All',
    riskGroup: 'All',
    overtime: 'All',
    overcost: 'All',
    canWrite: '',
    enabled: true,
  },
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SET_RISK_FILTERS:
      return {
        ...state,
        ...payload.riskFilters,
      };

    default:
      return state;
  }
};

export default riskFiltersReducer;
