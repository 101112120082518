import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { HelmetProvider, Helmet } from 'react-helmet-async';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { SENTRY_DSN, ENVIRONMENT_NAME } from './utils/config';
import { updateServiceWorker } from './actions/misc-actions';
import store from './store/store';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: ENVIRONMENT_NAME,
});

const onServiceWorkerUpdate = () => {
  store.dispatch(updateServiceWorker());
};

const ReduxApp = () => (
  <BrowserRouter>
    <Provider store={store}>
      <HelmetProvider>
        <Helmet titleTemplate="%s | RMS" defaultTitle="RMS" />
        <CssBaseline />
        <App />
      </HelmetProvider>
    </Provider>
  </BrowserRouter>
);

ReactDOM.render(<ReduxApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register(onServiceWorkerUpdate);
