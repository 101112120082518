import React, { Component } from 'react';
import * as compose from 'lodash.flowright';
import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { phaseToTimeUnits } from './utils';
import store from '../../store/store';

function findGroup(name, groups) {
  let result = null;
  groups?.forEach((group) => {
    if (group.name === name) {
      result = group;
    }
  });

  return result;
}

class EditFormComponent extends Component {
  constructor(props) {
    super(props);
    const { row } = this.props;

    this.state = {
      timeUnits: phaseToTimeUnits(row.phase),
      selectedGroup: findGroup(row.riskGroup, this.props.currentUser.myGroups),
      row: row,
      newRow: {
        id: row.id,
        specification: row.specification,
        migrationScenario: row.migrationScenario,
      },
    };
  }

  render() {
    const { classes, readOnly, currentUser } = this.props;
    const phaseDictionary = store.getState().dictionary['Dictionaries::Phase'];
    const statusDictionary =
      store.getState().dictionary['Dictionaries::Status'];
    const overtimeDictionary =
      store.getState().dictionary['Dictionaries::Overtime'];
    const overcostDictionary =
      store.getState().dictionary['Dictionaries::Overcost'];
    const strategyDictionary =
      store.getState().dictionary['Dictionaries::Strategy'];
    const probabilityDictionary =
      store.getState().dictionary['Dictionaries::Probability'];
    const subcategoryDictionary =
      store.getState().dictionary['Dictionaries::Subcategory'];
    const columns = store.getState().riskColumns.columns;

    const phaseColumn = columns.find((c) => c['key'] === 'phase');

    let users = this.state.selectedGroup ? this.state.selectedGroup.users : [];

    if (
      !users.find((u) => u.name === this.state.row.owner) &&
      this.state.row.owner
    ) {
      users.push({ name: this.state.row.owner });
    }

    return (
      <Grid container spacing={3}>
        {this.state.row.identifier && (
          <Grid item xs={12}>
            <TextField
              disabled
              id="standard-disabled"
              label="ID"
              defaultValue={this.state.row.identifier}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormControl
            className={classes.formControl}
            disabled={readOnly}
            required
          >
            <InputLabel id="demo-simple-select-label">Group</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.row.riskGroup}
              onChange={(event) =>
                this.setState({
                  ...this.state,
                  row: {
                    ...this.state.row,
                    riskGroup: event.target.value,
                    owner: '',
                  },
                  newRow: {
                    ...this.state.newRow,
                    riskGroup: event.target.value,
                    owner: '',
                  },
                  selectedGroup: findGroup(
                    event.target.value,
                    currentUser.myGroups
                  ),
                })
              }
            >
              {currentUser &&
                currentUser.myGroups?.map((group) => (
                  <MenuItem value={group.name}>{group.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} spacing={2}>
          <FormControl
            className={classes.formControl}
            disabled={readOnly}
            required
          >
            <InputLabel id="demo-simple-select-label">Risk Owner</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.row.owner}
              onChange={(event) => {
                this.setState({
                  ...this.state,
                  row: { ...this.state.row, owner: event.target.value },
                  newRow: { ...this.state.newRow, owner: event.target.value },
                });
              }}
            >
              {users.map((user) => (
                <MenuItem value={user.name}>{user.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl className={classes.formControlShort} disabled={readOnly}>
            <InputLabel id="demo-simple-select-label">Overcost</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.row.overcost}
              onChange={(event) =>
                this.setState({
                  ...this.state,
                  row: { ...this.state.row, overcost: event.target.value },
                  newRow: {
                    ...this.state.newRow,
                    overcost: event.target.value,
                  },
                })
              }
            >
              {overcostDictionary
                .filter((d) => d.active)
                .sort((a, b) => a.level - b.level)
                .map((item) => (
                  <MenuItem value={item.value}>{item.value}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <p>[M CAD]</p>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="standard-number"
            label="Overcost"
            type="number"
            value={this.state.row.overcost}
            onChange={(event) =>
              this.setState({
                ...this.state,
                row: { ...this.state.row, overcost: event.target.value },
                newRow: { ...this.state.newRow, overcost: event.target.value },
              })
            }
            InputLabelProps={{
              shrink: true,
            }}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={3}>
          <p>[M CAD]</p>
        </Grid>
        <Grid item xs={3}>
          <FormControl className={classes.formControlShort} disabled={readOnly}>
            <InputLabel id="demo-simple-select-label">Overtime</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.row.overtime}
              onChange={(event) =>
                this.setState({
                  ...this.state,
                  row: { ...this.state.row, overtime: event.target.value },
                  newRow: {
                    ...this.state.newRow,
                    overtime: event.target.value,
                  },
                })
              }
            >
              {overtimeDictionary
                .filter((d) => d.active)
                .sort((a, b) => a.level - b.level)
                .map((item) => (
                  <MenuItem value={item.value}>{item.value}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <p>{this.state.timeUnits}</p>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="standard-number"
            label="Overtime"
            type="number"
            value={this.state.row.overtime}
            onChange={(event) =>
              this.setState({
                ...this.state,
                row: { ...this.state.row, overtime: event.target.value },
                newRow: { ...this.state.newRow, overtime: event.target.value },
              })
            }
            InputLabelProps={{
              shrink: true,
            }}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={3}>
          <p>{this.state.timeUnits}</p>
        </Grid>
        <Grid item xs={3}>
          <FormControl className={classes.formControlShort} disabled={readOnly}>
            <InputLabel id="demo-simple-select-label">Likelihood</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.row.probability}
              onChange={(event) =>
                this.setState({
                  ...this.state,
                  row: { ...this.state.row, probability: event.target.value },
                  newRow: {
                    ...this.state.newRow,
                    probability: event.target.value,
                  },
                })
              }
            >
              {probabilityDictionary
                .sort((a, b) => a.level - b.level)
                .map((item) => (
                  <MenuItem value={item.value}>{item.value}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <p>[%]</p>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="standard-number"
            label="Likelihood"
            type="number"
            value={this.state.row.probability}
            onChange={(event) => {
              const v = parseFloat(event.target.value);
              if (!v || (v >= 0 && v <= 100)) {
                this.setState({
                  ...this.state,
                  row: { ...this.state.row, probability: event.target.value },
                  newRow: {
                    ...this.state.newRow,
                    probability: event.target.value,
                  },
                });
              }
            }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={3}>
          <p>[%]</p>
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.formControl} disabled={readOnly}>
            <InputLabel id="demo-simple-select-label">Subcategory</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.row.subcategory}
              onChange={(event) =>
                this.setState({
                  ...this.state,
                  row: { ...this.state.row, subcategory: event.target.value },
                  newRow: {
                    ...this.state.newRow,
                    subcategory: event.target.value,
                  },
                })
              }
            >
              {subcategoryDictionary.map((item) => (
                <MenuItem value={item.value}>{item.value}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.formControl} disabled={readOnly}>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.row.status}
              onChange={(event) =>
                this.setState({
                  ...this.state,
                  row: { ...this.state.row, status: event.target.value },
                  newRow: { ...this.state.newRow, status: event.target.value },
                })
              }
            >
              {statusDictionary.map((item) => (
                <MenuItem value={item.value}>{item.value}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {phaseColumn && phaseColumn.visible && (
          <Grid item xs={6}>
            <FormControl
              className={classes.formControl}
              disabled={readOnly}
              required
            >
              <InputLabel id="demo-simple-select-label">Phase</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.row.phase}
                onChange={(event) => {
                  this.setState({
                    ...this.state,
                    row: { ...this.state.row, phase: event.target.value },
                    newRow: { ...this.state.newRow, phase: event.target.value },
                    timeUnits: phaseToTimeUnits(event.target.value),
                  });
                }}
              >
                {phaseDictionary.map((item) => (
                  <MenuItem
                    value={item.value}
                    disabled={item.value === 'CONSTRUCTION'}
                  >
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={6}>
          <FormControl className={classes.formControl} disabled={readOnly}>
            <InputLabel id="demo-simple-select-label">Strategy</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.row.strategy}
              onChange={(event) =>
                this.setState({
                  ...this.state,
                  row: { ...this.state.row, strategy: event.target.value },
                  newRow: {
                    ...this.state.newRow,
                    strategy: event.target.value,
                  },
                })
              }
            >
              {strategyDictionary.map((item) => (
                <MenuItem value={item.value}>{item.value}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {
          <Grid item xs={12}>
            <TextField
              id="standard-multiline-flexible"
              label="Title"
              multiline
              rowsMax={2}
              style={{ width: 600 }}
              value={this.state.row.title}
              onChange={(event) =>
                this.setState({
                  ...this.state,
                  row: { ...this.state.row, title: event.target.value },
                  newRow: { ...this.state.newRow, title: event.target.value },
                })
              }
              disabled={readOnly}
            />
          </Grid>
        }
        <Grid item xs={12}>
          <TextField
            id="standard-multiline-flexible"
            label="Specification"
            multiline
            rowsMax={6}
            style={{ width: 600 }}
            value={this.state.row.specification}
            onChange={(event) =>
              this.setState({
                ...this.state,
                row: { ...this.state.row, specification: event.target.value },
                newRow: {
                  ...this.state.newRow,
                  specification: event.target.value,
                },
              })
            }
            disabled={readOnly}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="standard-multiline-flexible"
            label="Response plan"
            multiline
            rowsMax={5}
            style={{ width: 600 }}
            value={this.state.row.migrationScenario}
            onChange={(event) =>
              this.setState({
                ...this.state,
                row: {
                  ...this.state.row,
                  migrationScenario: event.target.value,
                },
                newRow: {
                  ...this.state.newRow,
                  migrationScenario: event.target.value,
                },
              })
            }
            disabled={readOnly}
          />
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme) => ({
  formControl: {
    width: '250px',
  },
  formControlShort: {
    width: '210px',
  },
});

export default compose(withStyles(styles))(EditFormComponent);
