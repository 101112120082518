import React, { PropsWithChildren } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { IRiskGroup } from './types';
import mapConnection from '../../utils/connectionMapper';
import { IUser } from '../Analysis/types';

const GROUPS_QUERY = gql`
  query riskGroups {
    riskGroups {
      edges {
        node {
          id
          name
          leader {
            id
            email
            name
          }
          parentGroup {
            id
            name
          }
          abbreviation
          users {
            id
            email
            name
          }
          groupChildrenIds
          number
          defaultRange
          defaultGlobality
        }
      }
    }
    users {
      edges {
        node {
          id
          name
          email
          createdAt
        }
      }
    }
  }
`;

export interface IWithGroups {
  groups: IRiskGroup[];
  users: IUser[];
  loading?: boolean;
  history?: any;
}

function withQuery(WrappedComponent: React.FC<PropsWithChildren<IWithGroups>>) {
  return (props: PropsWithChildren<IWithGroups>) => {
    const { loading, error, data } = useQuery<any, {}>(GROUPS_QUERY, {
      variables: {},
      fetchPolicy: 'no-cache',
    });

    if (error) return <p>ERROR</p>;

    const groups = data ? mapConnection(data.riskGroups) : [];
    const users = data ? mapConnection(data.users) : [];

    return (
      <WrappedComponent
        {...props}
        groups={groups}
        users={users}
        loading={loading}
      />
    );
  };
}

export default withQuery;
