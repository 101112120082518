import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useState, useCallback, useMemo } from 'react';
import ReactDataGrid, { Row as GridRow } from 'react-data-grid';
import { Button, Grid } from '@material-ui/core';
import { createPortal } from 'react-dom';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import AddDialog from './AddDialog';
import EditDialog from './EditDialog';
import DeleteDialog from './DeleteDialog';
import ResetDialog from './ResetDialog';
import moment from 'moment';

const useStyles = makeStyles((theme) =>
  createStyles({
    textFilter: {
      width: 180,
    },
  })
);

export function UsersList(props) {
  const classes = useStyles();
  const { groups } = props;

  const [rows, setRows] = useState(
    props.users.map((r) => {
      return {
        ...r,
        createdAt: moment(r.createdAt).format('yyyy-MM-DD / HH:mm'),
      };
    })
  );
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [[sortColumn, sortDirection], setSort] = useState(['id', 'NONE']);
  const [filters, setFilters] = useState({
    name: '',
    email: '',
    phone: '',
    createdAt: '',
    position: '',
    abbreviation: '',
    office: '',
  });

  const columns = useMemo(() => {
    return [
      {
        name: 'User Name',
        key: 'name',
        width: 200,
        filterRenderer: (p) => (
          <div className="rdg-filter-container">
            <input
              className={`rdg-filter ${classes.textFilter}`}
              value={p.value}
              onChange={(e) => p.onChange(e.target.value)}
            />
          </div>
        ),
      },
      {
        name: 'Email Address',
        key: 'email',
        width: 200,
        filterRenderer: (p) => (
          <div className="rdg-filter-container">
            <input
              className={`rdg-filter ${classes.textFilter}`}
              value={p.value}
              onChange={(e) => p.onChange(e.target.value)}
            />
          </div>
        ),
      },
      {
        name: 'Phone',
        key: 'phone',
        width: 200,
        filterRenderer: (p) => (
          <div className="rdg-filter-container">
            <input
              className={`rdg-filter ${classes.textFilter}`}
              value={p.value}
              onChange={(e) => p.onChange(e.target.value)}
            />
          </div>
        ),
      },
      {
        name: 'Position',
        key: 'position',
        width: 200,
        filterRenderer: (p) => (
          <div className="rdg-filter-container">
            <input
              className={`rdg-filter ${classes.textFilter}`}
              value={p.value}
              onChange={(e) => p.onChange(e.target.value)}
            />
          </div>
        ),
      },
      {
        name: 'Abbr.',
        key: 'abbreviation',
        width: 200,
        filterRenderer: (p) => (
          <div className="rdg-filter-container">
            <input
              className={`rdg-filter ${classes.textFilter}`}
              value={p.value}
              onChange={(e) => p.onChange(e.target.value)}
            />
          </div>
        ),
      },
      {
        name: 'Office',
        key: 'office',
        width: 200,
        filterRenderer: (p) => (
          <div className="rdg-filter-container">
            <input
              className={`rdg-filter ${classes.textFilter}`}
              value={p.value}
              onChange={(e) => p.onChange(e.target.value)}
            />
          </div>
        ),
      },
      {
        name: 'User status',
        key: 'status',
        width: 200,
        filterRenderer: (p) => (
          <div className="rdg-filter-container">
            <input
              className={`rdg-filter ${classes.textFilter}`}
              value={p.value}
              onChange={(e) => p.onChange(e.target.value)}
            />
          </div>
        ),
      },
      {
        name: 'Role',
        key: 'role',
        width: 200,
        filterRenderer: (p) => (
          <div className="rdg-filter-container">
            <input
              className={`rdg-filter ${classes.textFilter}`}
              value={p.value}
              onChange={(e) => p.onChange(e.target.value)}
            />
          </div>
        ),
      },
      {
        name: 'Created at',
        key: 'createdAt',
        width: 200,
        filterRenderer: (p) => (
          <div className="rdg-filter-container">
            <input
              className={`rdg-filter ${classes.textFilter}`}
              value={p.value}
              onChange={(e) => p.onChange(e.target.value)}
            />
          </div>
        ),
      },
      {
        name: 'Group member',
        key: 'riskGroup',
        filterRenderer: (p) => (
          <div className="rdg-filter-container">
            <input
              className={`rdg-filter ${classes.textFilter}`}
              value={p.value}
              onChange={(e) => p.onChange(e.target.value)}
            />
          </div>
        ),
      },
    ];
  }, [classes.textFilter]);

  const filteredRows = useMemo(() => {
    return rows.filter((r) => {
      return (
        (filters.email
          ? r.email.toLowerCase().includes(filters.email.toLowerCase())
          : true) &&
        (filters.name
          ? r.name.toLowerCase().includes(filters.name.toLowerCase())
          : true) &&
        (filters.phone
          ? r.phone.toLowerCase().includes(filters.phone.toLowerCase())
          : true) &&
        (filters.createdAt
          ? r.createdAt.toLowerCase().includes(filters.createdAt.toLowerCase())
          : true) &&
        (filters.position
          ? r.position.toLowerCase().includes(filters.position.toLowerCase())
          : true) &&
        (filters.abbreviation
          ? r.abbreviation
              .toLowerCase()
              .includes(filters.abbreviation.toLowerCase())
          : true) &&
        (filters.office
          ? r.office.toLowerCase().includes(filters.office.toLowerCase())
          : true)
      );
    });
  }, [rows, filters]);

  const sortedRows = useMemo(() => {
    if (sortDirection === 'NONE') return filteredRows || rows;

    let sortedRows = [...(filteredRows || rows)];

    switch (sortColumn) {
      case 'name':
      case 'email':
      case 'phone':
      case 'position':
      case 'abbreviation':
      case 'office':
        sortedRows = sortedRows.sort(
          (a, b) => a[sortColumn] && a[sortColumn].localeCompare(b[sortColumn])
        );
        break;
      case 'id':
        sortedRows = sortedRows.sort((a, b) => a[sortColumn] - b[sortColumn]);
        break;
      default:
    }

    return sortDirection === 'DESC' ? sortedRows.reverse() : sortedRows;
  }, [rows, filteredRows, sortDirection, sortColumn]);

  const handleSort = useCallback((columnKey, direction) => {
    setSort([columnKey, direction]);
  }, []);

  function rowKeyGetter(row) {
    return row.id;
  }

  function onRowDoubleClick(row, rowIdx) {
    handleEditDialogOpen({}, { rowIdx: rowIdx });
  }

  function RowRenderer(props) {
    return (
      <ContextMenuTrigger
        id="grid-context-menu"
        collect={() => ({ rowIdx: props.rowIdx })}
      >
        {
          <GridRow
            onDoubleClick={() => onRowDoubleClick(props.row, props.rowIdx)}
            {...props}
          />
        }
      </ContextMenuTrigger>
    );
  }

  function handleAddDialogOpen() {
    setAddDialogOpen(true);
  }
  function handleEditDialogOpen(e, { rowIdx }) {
    setSelectedUser(sortedRows[rowIdx]);
    setEditDialogOpen(true);
  }
  // function handleResetDialogOpen(e, { rowIdx }) {
  //   setSelectedUser(sortedRows[rowIdx]);
  //   setResetDialogOpen(true);
  // }
  function handleDeleteDialogOpen(e, { rowIdx }) {
    setSelectedUser(sortedRows[rowIdx]);
    setDeleteDialogOpen(true);
  }
  function handleAddDialogClose(user) {
    if (user != null) {
      const myRows = rows.slice();
      myRows.push(user);
      setRows(myRows);
    }
    setAddDialogOpen(false);
  }
  function handleEditDialogClose(user) {
    if (user) {
      const myRows = rows.slice();
      const rIdx = myRows.findIndex((r) => r.id === user.id);

      if (rIdx !== null) {
        myRows[rIdx] = user;
        setRows(myRows);
      }
    }
    setEditDialogOpen(false);
  }
  function handleResetDialogClose(user) {
    setResetDialogOpen(false);
  }
  function handleDeleteDialogClose(user) {
    if (user) {
      const myRows = rows.slice().filter((r) => r.id !== user.id);
      setRows(myRows);
    }
    setDeleteDialogOpen(false);
  }

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddDialogOpen}
        >
          New
        </Button>
      </Grid>
      <Grid item xs={12}>
        <ReactDataGrid
          style={{
            width: '100%',
            height: window !== undefined ? window.innerHeight * 0.82 : 100,
          }}
          rowKeyGetter={rowKeyGetter}
          columns={columns}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={handleSort}
          rows={sortedRows}
          enableFilterRow={true}
          filters={filters}
          rowRenderer={RowRenderer}
          onFiltersChange={setFilters}
        />
        {createPortal(
          <ContextMenu id="grid-context-menu">
            <MenuItem onClick={handleEditDialogOpen}>Edit</MenuItem>
            <MenuItem onClick={handleDeleteDialogOpen}>Disable</MenuItem>
          </ContextMenu>,
          document.body
        )}
        <AddDialog
          dialogOpen={addDialogOpen}
          groups={groups}
          handleDialogClose={handleAddDialogClose}
        />
        <ResetDialog
          dialogOpen={resetDialogOpen}
          handleDialogClose={handleResetDialogClose}
          user={selectedUser}
        />
        <EditDialog
          dialogOpen={editDialogOpen}
          groups={groups}
          user={selectedUser}
          handleDialogClose={handleEditDialogClose}
        />
        <DeleteDialog
          dialogOpen={deleteDialogOpen}
          handleDialogClose={handleDeleteDialogClose}
          user={selectedUser}
        />
      </Grid>
    </Grid>
  );
}

export default UsersList;
