import { SET_RISK_COLUMNS } from '../actions/action-types';

const riskColumnsReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_RISK_COLUMNS:
      return {
        ...state,
        columns: payload.riskColumns,
      };

    default:
      return state;
  }
};

export default riskColumnsReducer;
