import React from 'react';
import { useRoutes } from 'react-router-dom';
import { AuthProvider } from './contexts/CognitoContext';
import { LayoutProvider } from './contexts/LayoutContext';
import { SidebarProvider } from './contexts/SidebarContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { ApolloProvider } from '@apollo/client';
import routes from './routes';
import ChartJsDefaults from './utils/ChartJsDefaults';
import client from './apollo-client';

const App = () => {
  const content = useRoutes(routes);

  return (
    <ThemeProvider>
      <SidebarProvider>
        <LayoutProvider>
          <ChartJsDefaults />
          <AuthProvider>
            <ApolloProvider client={client}>{content}</ApolloProvider>
          </AuthProvider>
        </LayoutProvider>
      </SidebarProvider>
    </ThemeProvider>
  );
};

export default App;
