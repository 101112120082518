import React, { Component } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Spinner from '../../UI/Spinner';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import { Grid, withStyles } from '@material-ui/core';
import {
  DEFAULT_GLOBALITY_FILTER,
  DEFAULT_RANGE_FILTER,
  DEFAULT_PHASE_FILTER,
  DEFAULT_STATUS_FILTER,
  DEFAULT_PRIORITY_FILTER,
} from '../../../utils/consts';
import { createStyles } from '@material-ui/core/styles';
import RisksPreviewWindow from '../../RisksPreviewWindow';

const STATS_QUERY = gql`
  query statistics(
    $reportName: String
    $filter: [RiskStatsFilterInputObject!]
  ) {
    entireRegisterTable(reportName: $reportName, filter: $filter) {
      type
      status
      leftPndCount
      leftNewCount
      leftClsCount
      leftTskCount
      leftSum
      leftTotalSum
      leftPndIds
      leftNewIds
      leftClsIds
      leftTskIds
      leftSumIds
      leftTotalSumIds
    }
    currentReport {
      label
    }
  }
`;

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      textAlign: 'center',
      margin: '2px',
      padding: '2px',
      height: 55,
      borderRight: '1px solid rgb(238, 238, 238)',
    },
    body: {
      textAlign: 'center',
      margin: '2px',
      padding: '2px',
      borderRight: '1px solid rgb(238, 238, 238)',
      height: 55,
    },
  })
)(TableCell);

const evalClassName = (rowType, classes) => {
  // eslint-disable-next-line no-eval
  return eval(`classes.${rowType}`);
};

class EntireRegisterStats extends Component {
  render() {
    const { classes, rows } = this.props;

    return (
      <Grid item container xs={12} alignItems="center" justify="center">
        <Paper className={classes.paperTable}>
          <TableContainer component={Paper} align="center">
            <Table
              style={{ width: '99%' }}
              aria-label="simple table"
              size="medium"
            >
              <TableHead>
                <TableRow style={{ height: 90 }}>
                  <TableCell
                    align="center"
                    colSpan={9}
                    style={{ fontWeight: 'bold', fontSize: 18 }}
                  >
                    ENTIRE REGISTER
                  </TableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell
                    style={{
                      width: 30,
                      borderRight: '1px dashed gray',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    (-TSK)
                  </StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>PND</StyledTableCell>
                  <StyledTableCell>NEW</StyledTableCell>
                  <StyledTableCell>CLS</StyledTableCell>
                  <StyledTableCell>TSK</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .filter((r) => r.status == null)
                  .map((row) => (
                    <TableRow key={row.type}>
                      <StyledTableCell
                        style={{ borderRight: '1px dashed gray' }}
                      >
                        <RisksPreviewWindow
                          value={row.leftTotalSum}
                          ids={row.leftTotalSumIds}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        style={
                          row.type === 'summary' ? { fontWeight: 'bold' } : {}
                        }
                      >
                        <RisksPreviewWindow
                          value={row.leftSum}
                          ids={row.leftSumIds}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        className={evalClassName(row.type, classes)}
                      >
                        <RisksPreviewWindow
                          value={row.leftPndCount}
                          ids={row.leftPndIds}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        className={evalClassName(row.type, classes)}
                      >
                        <RisksPreviewWindow
                          value={row.leftNewCount}
                          ids={row.leftNewIds}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        className={evalClassName(row.type, classes)}
                      >
                        <RisksPreviewWindow
                          value={row.leftClsCount}
                          ids={row.leftClsIds}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        className={evalClassName(row.type, classes)}
                      >
                        <RisksPreviewWindow
                          value={row.leftTskCount}
                          ids={row.leftTskIds}
                        />
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    );
  }
}

const withQuery = (WrappedComponent) => (props) => {
  const { loading, data } = useQuery(STATS_QUERY, {
    variables: {
      reportName: props.reportName,
      filter: [
        {
          name: 'phase',
          includeValues: props.filters
            ? props.filters.phase
            : DEFAULT_PHASE_FILTER,
        },
        {
          name: 'status',
          includeValues: props.filters
            ? props.filters.status
            : DEFAULT_STATUS_FILTER,
        },
        {
          name: 'globality',
          includeValues: props.filters
            ? props.filters.globality
            : DEFAULT_GLOBALITY_FILTER,
        },
        {
          name: 'range',
          includeValues: props.filters
            ? props.filters.range
            : DEFAULT_RANGE_FILTER,
        },
        {
          name: 'priority',
          includeValues: props.filters
            ? props.filters.priority
            : DEFAULT_PRIORITY_FILTER,
        },
      ],
    },
  });
  const stats = data ? data.entireRegisterTable : [];
  const reportName = data ? data.currentReport.label : [];
  return (
    <WrappedComponent
      {...props}
      stats={stats}
      loading={loading}
      reportName={props.reportName || reportName}
    />
  );
};

const EntireRegisterTable = (props) => {
  const { loading, stats, classes } = props;
  return (
    <Card style={{ minWidth: 550, height: 400 }}>
      <CardContent>
        {loading ? (
          <Spinner />
        ) : (
          stats && (
            <EntireRegisterStats
              rows={stats}
              classes={classes}
              reportName={props.reportName}
            />
          )
        )}
      </CardContent>
    </Card>
  );
};

const styles = (theme) => ({
  priority_i: {
    backgroundColor: 'red',
    fontWeight: 'bold',
    color: 'white',
  },
  priority_ii: {
    backgroundColor: 'yellow',
  },
  priority_iii: {
    backgroundColor: 'lime',
  },
  paperTable: {
    width: '100%',
  },
});

export default withStyles(styles)(withQuery(EntireRegisterTable));
