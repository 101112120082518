import React, { Component, useContext } from 'react';
import { Dialog, DialogContent, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SplitRiskEntityMutation from '../../mutations/SplitRiskEntityMutation';
import remapEntity from '../../utils/remapRiskTicketFields';
import Divider from '@material-ui/core/Divider';
import { useMutation } from '@apollo/client';
import NotyfContext from '../../contexts/NotyfContext';
import {
  displayErrorMessage,
  displaySuccessMessage,
} from '../../utils/displayMessage';

function getSteps() {
  return ['Select split options', 'New records values', 'Summary'];
}

function initRows(n, splitMode, row, groups) {
  let arr = [];

  for (let i = 0; i < n; ++i) {
    if (splitMode === 'keep' && i === 0) {
      arr.push({
        id: row.id,
        specification: row.specification,
        riskGroup: row.riskGroup,
        owner: row.owner,
        phase: row.phase,
        selectedGroup: findGroup(row.riskGroup, groups),
      });
    } else {
      arr.push({
        specification: '',
        riskGroup: '',
        owner: '',
        phase: '',
        selectedGroup: null,
      });
    }
  }

  return arr;
}

function findGroup(name, groups) {
  let result = null;
  groups.forEach((group) => {
    if (group.name === name) {
      result = group;
    }
  });

  return result;
}

class SplitModal extends Component {
  handleSplit = async () => {
    const { splitRiskMutationHandler, handleSplitModalClose, notyf } =
      this.props;
    this.setState({ loading: true });

    try {
      let result = await splitRiskMutationHandler({
        variables: {
          input: {
            riskEventId: this.state.riskEntityId,
            splitMode: this.state.splitMode,
            events: this.state.splitRows.map((r) => {
              return {
                id: r.id,
                specification: r.specification,
                riskGroup: r.riskGroup,
                owner: r.owner,
              };
            }),
          },
        },
      });

      const splitResult = result.data.riskEventsSplit;
      const errors = result.data.riskEventsSplit.errors;
      const events = result.data.riskEventsSplit.riskEvents;

      if (splitResult !== null && errors != null && errors.length > 0) {
        displayErrorMessage(notyf, errors[0].message);
      } else {
        displaySuccessMessage(
          notyf,
          'Risk event has been splited successfully'
        );
        handleSplitModalClose(events.map((entity) => remapEntity(entity)));
      }
    } catch (err) {
      displayErrorMessage(notyf, err);
      console.error(err);
    }
  };

  constructor(props) {
    super(props);
    const { row, currentUser } = props;
    this.state = {
      currentUser: currentUser,
      riskEntityId: row.id,
      activeStep: 0,
      splitNumber: 2,
      splitMode: 'keep',
      splitRows: initRows(2, 'keep', row, currentUser.myGroups),
    };
  }

  getStepContent = (stepIndex) => {
    const { classes, row } = this.props;

    switch (stepIndex) {
      case 0:
        return (
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Source record action({row.identifier}):
            </FormLabel>
            <RadioGroup
              aria-label="splitMode"
              value={this.state.splitMode}
              name="splitMode1"
              onChange={(event) =>
                this.setState({
                  ...this.state,
                  splitMode: event.target.value,
                  splitRows: initRows(
                    this.state.splitNumber,
                    event.target.value,
                    row,
                    this.state.currentUser.myGroups
                  ),
                })
              }
            >
              <FormControlLabel value="keep" control={<Radio />} label="Keep" />
              <FormControlLabel
                value="close"
                control={<Radio />}
                label="Close"
              />
            </RadioGroup>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <TextField
              id="standard-number"
              label="Split number"
              type="number"
              value={this.state.splitNumber}
              onChange={(event) => {
                let val = event.target.value;
                if (val > 8) {
                  val = 8;
                }
                if (val < 2) {
                  val = 2;
                }

                this.setState({
                  ...this.state,
                  splitNumber: val,
                  splitRows: initRows(
                    val,
                    this.state.splitMode,
                    row,
                    this.state.currentUser.myGroups
                  ),
                });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        );
      case 1:
        let records = [];
        for (let i = 0; i < this.state.splitNumber; ++i) {
          let splitRow = this.state.splitRows[i];

          records.push(
            <Grid
              item
              container
              xs={12}
              style={{ marginBottom: 10, padding: '15 15 15 15' }}
            >
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Risk Group
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={splitRow.riskGroup}
                    onChange={(event) => {
                      let newRows = this.state.splitRows;
                      newRows[i].riskGroup = event.target.value;
                      newRows[i].selectedGroup = findGroup(
                        event.target.value,
                        this.state.currentUser.myGroups
                      );
                      this.setState({ ...this.state, splitRows: newRows });
                    }}
                  >
                    {this.state.currentUser &&
                      this.state.currentUser.myGroups.map((group) => (
                        <MenuItem value={group.name}>{group.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Risk Owner
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={splitRow.owner}
                    onChange={(event) => {
                      let newRows = this.state.splitRows;
                      newRows[i].owner = event.target.value;
                      this.setState({ ...this.state, splitRows: newRows });
                    }}
                  >
                    {splitRow.selectedGroup &&
                      splitRow.selectedGroup.users.map((user) => (
                        <MenuItem value={user.name}>{user.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="standard-multiline-flexible"
                    label="Specification"
                    multiline
                    rowsMax={3}
                    style={{ width: 750 }}
                    value={splitRow.specification}
                    onChange={(event) => {
                      let newRows = this.state.splitRows;
                      newRows[i].specification = event.target.value;
                      this.setState({ ...this.state, splitRows: newRows });
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          );
        }

        return (
          <Grid container xs={12}>
            {records}
          </Grid>
        );
      case 2:
        return (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Group</TableCell>
                  <TableCell align="center">Risk Owner</TableCell>
                  <TableCell align="center">Phase</TableCell>
                  <TableCell align="center">Specification</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.splitRows.map((row) => (
                  <TableRow key={row.riskGroup}>
                    <TableCell>{row.riskGroup}</TableCell>
                    <TableCell>{row.owner}</TableCell>
                    <TableCell>{row.phase}</TableCell>
                    <TableCell>{row.specification}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      default:
        return 'Unknown stepIndex';
    }
  };

  handleNext = () => {
    this.setState({ ...this.stete, activeStep: this.state.activeStep + 1 });
  };

  handleBack = () => {
    this.setState({ ...this.stete, activeStep: this.state.activeStep - 1 });
  };

  render() {
    const { splitModalOpen, handleSplitModalClose, classes } = this.props;
    const steps = getSteps();

    return (
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={splitModalOpen}
        onClose={handleSplitModalClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <Stepper activeStep={this.state.activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Typography className={classes.instructions}>
            {this.getStepContent(this.state.activeStep)}
          </Typography>
          <Grid container xs={12}>
            <Grid item xs={6} style={{ marginTop: 20 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleSplitModalClose()}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} align="right" style={{ marginTop: 20 }}>
              <Button
                disabled={this.state.activeStep === 0}
                onClick={this.handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (this.state.activeStep === steps.length - 1) {
                    this.handleSplit();
                  } else {
                    this.handleNext();
                  }
                }}
              >
                {this.state.activeStep === steps.length - 1 ? 'Split' : 'Next'}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}
const styles = (theme) => ({
  formControl: {
    width: '250px',
  },
  paper: {
    position: 'absolute',
    width: 900,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

const SplitModalWrapper = (props) => {
  const notyf = useContext(NotyfContext);
  const { row } = props;
  const [splitRiskMutationHandler] = useMutation(SplitRiskEntityMutation);
  return (
    <div>
      {row ? (
        <SplitModal
          splitRiskMutationHandler={splitRiskMutationHandler}
          notyf={notyf}
          {...props}
        />
      ) : null}
    </div>
  );
};

export default withStyles(styles)(SplitModalWrapper);
