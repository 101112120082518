import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import PrioritiesChart from '../Analysis/Charts/PrioritiesChart';
import PriorityReportTable from '../Analysis/PriorityReportTable';
import {
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Paper,
} from '@material-ui/core';
import EntireRegister from '../Analysis/Tables/EntireRegister';

const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      width: 200,
    },
  })
);

const SummaryTablePage = (props) => {
  const classes = useStyles();
  const [reportName, setReportName] = React.useState(props.reportName);
  const [phaseFilters, setPhaseFilters] = React.useState([
    'phase_construction',
    'phase_project',
    'phase_operation',
  ]);
  const [statusFilters, setStatusFilters] = React.useState([
    'status_new',
    'status_pending',
    'status_closed',
  ]);
  const [globalityFilters, setGlobalityFilters] = React.useState([
    'globality_spc',
    'globality_glb',
  ]);
  const [rangeFilters, setRangeFilters] = React.useState([
    'range_sub',
    'range_etf',
  ]);
  const [priorityFilters, setPriorityFilters] = React.useState([
    'priority_i',
    'priority_ii',
    'priority_iii',
  ]);

  const handleReportChange = (event) => {
    setReportName(event?.target.value);
  };

  const handlePhaseChange = (event) => {
    if (event.target.checked) {
      setPhaseFilters(phaseFilters.concat(event.target.name));
    } else {
      setPhaseFilters(phaseFilters.filter((p) => p !== event.target.name));
    }
  };

  const handleStatusChange = (event) => {
    if (event.target.checked) {
      setStatusFilters(statusFilters.concat(event.target.name));
    } else {
      setStatusFilters(statusFilters.filter((p) => p !== event.target.name));
    }
  };

  const handleGlobalityChange = (event) => {
    if (event.target.checked) {
      setGlobalityFilters(globalityFilters.concat(event.target.name));
    } else {
      setGlobalityFilters(
        globalityFilters.filter((p) => p !== event.target.name)
      );
    }
  };

  const handleRangeChange = (event) => {
    if (event.target.checked) {
      setRangeFilters(rangeFilters.concat(event.target.name));
    } else {
      setRangeFilters(rangeFilters.filter((p) => p !== event.target.name));
    }
  };

  const handlePriorityChange = (event) => {
    if (event.target.checked) {
      setPriorityFilters(priorityFilters.concat(event.target.name));
    } else {
      setPriorityFilters(
        priorityFilters.filter((p) => p !== event.target.name)
      );
    }
  };

  useEffect(() => {
    setReportName(props.reportName);
  }, [props.reportName]);

  return (
    <Grid container spacing={2} alignItems="center" justify="center">
      <Grid
        container
        alignItems="center"
        alignContent="center"
        justify="center"
      >
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">Report</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={reportName}
            onChange={handleReportChange}
            label="Report"
          >
            <MenuItem value={''}>None</MenuItem>
            {props.reports.map((report) => (
              <MenuItem value={report.label}>{report.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        xs={12}
        lg={6}
        xl={4}
        item
        container
        alignContent="center"
        alignItems="center"
        justify="center"
      >
        <PrioritiesChart
          reportName={reportName}
          filters={{
            status: statusFilters,
            phase: phaseFilters,
            globality: globalityFilters,
            range: rangeFilters,
            priority: priorityFilters,
          }}
        />
      </Grid>
      <Grid
        xs={12}
        lg={6}
        xl={4}
        item
        container
        alignContent="center"
        alignItems="center"
        justify="center"
      >
        <EntireRegister
          reportName={reportName}
          filters={{
            status: statusFilters,
            phase: phaseFilters,
            globality: globalityFilters,
            range: rangeFilters,
            priority: priorityFilters,
          }}
        />
      </Grid>
      <Grid
        xs={12}
        lg={6}
        xl={4}
        item
        container
        alignContent="center"
        alignItems="center"
        justify="center"
      >
        <PriorityReportTable
          reportName={reportName}
          filters={{
            status: statusFilters,
            phase: phaseFilters,
            globality: globalityFilters,
            range: rangeFilters,
            priority: priorityFilters,
          }}
        />
      </Grid>
      <Grid container item spacing={2} style={{ marginTop: 20 }}>
        <Grid item xs={2}>
          <Paper elevation={1} style={{ padding: 7 }}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">PHASE</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={phaseFilters.includes('phase_construction')}
                      onChange={handlePhaseChange}
                      name="phase_construction"
                    />
                  }
                  label="Construction"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={phaseFilters.includes('phase_project')}
                      onChange={handlePhaseChange}
                      name="phase_project"
                    />
                  }
                  label="Project"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={phaseFilters.includes('phase_operation')}
                      onChange={handlePhaseChange}
                      name="phase_operation"
                    />
                  }
                  label="Operation"
                />
              </FormGroup>
            </FormControl>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper elevation={1} style={{ padding: 7 }}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">STATUS</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={statusFilters.includes('status_new')}
                      onChange={handleStatusChange}
                      name="status_new"
                    />
                  }
                  label="New"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={statusFilters.includes('status_pending')}
                      onChange={handleStatusChange}
                      name="status_pending"
                    />
                  }
                  label="Pending"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={statusFilters.includes('status_closed')}
                      onChange={handleStatusChange}
                      name="status_closed"
                    />
                  }
                  label="Closed"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={statusFilters.includes('status_link')}
                      onChange={handleStatusChange}
                      name="status_link"
                    />
                  }
                  label="Link"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={statusFilters.includes('status_relocated')}
                      onChange={handleStatusChange}
                      name="status_relocated"
                    />
                  }
                  label="Relocated"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={statusFilters.includes('status_task')}
                      onChange={handleStatusChange}
                      name="status_task"
                    />
                  }
                  label="Task"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={statusFilters.includes('status_preview')}
                      onChange={handleStatusChange}
                      name="status_preview"
                    />
                  }
                  label="Preview"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={statusFilters.includes('status_next_stage')}
                      onChange={handleStatusChange}
                      name="status_next_stage"
                    />
                  }
                  label="Next Stage"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={statusFilters.includes('status_incomplete')}
                      onChange={handleStatusChange}
                      name="status_incomplete"
                    />
                  }
                  label="Incomplete"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={statusFilters.includes('status_test')}
                      onChange={handleStatusChange}
                      name="status_test"
                    />
                  }
                  label="Test"
                />
              </FormGroup>
            </FormControl>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper elevation={1} style={{ padding: 7 }}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">GLOBALITY</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={globalityFilters.includes('globality_glb')}
                      onChange={handleGlobalityChange}
                      name="globality_glb"
                    />
                  }
                  label="Global"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={globalityFilters.includes('globality_spc')}
                      onChange={handleGlobalityChange}
                      name="globality_spc"
                    />
                  }
                  label="Local"
                />
              </FormGroup>
            </FormControl>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper elevation={1} style={{ padding: 7 }}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">RANGE</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={rangeFilters.includes('range_etf')}
                      onChange={handleRangeChange}
                      name="range_etf"
                    />
                  }
                  label="ETF"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={rangeFilters.includes('range_sub')}
                      onChange={handleRangeChange}
                      name="range_sub"
                    />
                  }
                  label="SUB"
                />
              </FormGroup>
            </FormControl>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper elevation={1} style={{ padding: 7 }}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">PRIORITY</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={priorityFilters.includes('priority_i')}
                      onChange={handlePriorityChange}
                      name="priority_i"
                    />
                  }
                  label="(I)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={priorityFilters.includes('priority_ii')}
                      onChange={handlePriorityChange}
                      name="priority_ii"
                    />
                  }
                  label="(II)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={priorityFilters.includes('priority_iii')}
                      onChange={handlePriorityChange}
                      name="priority_iii"
                    />
                  }
                  label="(III)"
                />
              </FormGroup>
            </FormControl>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SummaryTablePage;
