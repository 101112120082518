import React, { Component } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  withStyles,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import UsersSendResetMutation from '../../mutations/UsersSendResetMutation';

class AddDialog extends Component {
  handleSend = async () => {
    const { client, handleDialogClose, user } = this.props;

    try {
      let result = await client.mutate({
        mutation: UsersSendResetMutation,
        variables: {
          input: { email: user ? user.email : '' },
        },
      });

      if (result.errors != null && result.errors.length > 0) {
        this.setState({
          ...this.state,
          errorMessage: result.errors[0].message,
        });
      } else if (
        result != null &&
        result.data.usersSendReset != null &&
        result.data.usersSendReset.errors != null &&
        result.data.usersSendReset.errors.length > 0
      ) {
        this.setState({
          ...this.state,
          errorMessage: result.data.usersSendReset.errors[0].message,
        });
      } else {
        handleDialogClose(result.data.usersSendReset.message);
      }
    } catch (err) {
      console.error(err);
      this.setState({ ...this.state, errorMessage: err.message });
    }
  };

  render() {
    const { dialogOpen, handleDialogClose, user } = this.props;

    return (
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Password reset</DialogTitle>
        <DialogContent>
          <Typography>
            Do you want to send password reset request for user:{' '}
            {user ? user.email : ''} ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => handleDialogClose()}
            color="secondary"
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => this.handleSend()}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
const styles = (theme) => ({
  formControl: {
    width: '300px',
  },
});

export default withStyles(styles)(AddDialog);
