import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import priorityColor from '../../utils/priorityColor';

export const LongTextFormatter = ({ value }) => {
  const direction = 'left';
  return (
    <span className="me-1" key={direction}>
      <OverlayTrigger
        // @ts-ignore
        placement={direction}
        overlay={<Tooltip id={`tooltip-${direction}`}>{value}</Tooltip>}
      >
        <span style={{ width: 100 }}>{value}</span>
      </OverlayTrigger>
    </span>
  );
};

export const PriorityFormatter = ({ status, priorityId, value }) => {
  const colors = priorityColor(priorityId);
  const className = `${colors[0]} ${colors[1]}`;

  return (
    <div
      className={status === 'CLOSED' ? '' : className}
      style={{
        textAlign: 'center',
        width: '100%',
      }}
    >
      {value}
    </div>
  );
};

export const OvertimeFormatter = ({ value, row }) => {
  // let units = ' ' + phaseToTimeUnits(row.phase);
  return <div align="right">{value ? value : ''}</div>;
};

export const OvercostFormatter = ({ value }) => {
  return <div align="right">{value ? value : ''}</div>;
};

export const ProbabilityFormatter = ({ value }) => {
  return <div align="right">{value ? value + ' %' : ''}</div>;
};
