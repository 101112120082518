import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import mapConnection from '../../utils/connectionMapper';
import remapEntity from '../../utils/remapRiskTicketFields';
import { Link } from '@material-ui/core';
import store from '../../store/store';

const RISK_ENTITIES_QUERY = gql`
  query entities {
    riskEvents {
      edges {
        node {
          id
          identifier
          specification
          migrationScenario
          title
          owner {
            name
          }
          planOwner {
            name
          }
          createdBy {
            name
          }
          updatedBy {
            name
          }
          category {
            label
          }
          subcategory {
            label
          }
          strategy {
            label
          }
          phase {
            label
          }
          status {
            label
          }
          priority {
            id
            label
          }
          impact {
            label
          }
          globality {
            label
          }
          range {
            label
          }
          reportingCycle {
            id
            title
          }
          riskGroup {
            name
          }
          probability
          overtime
          overcost
          lastRcComment
          lastComment

          canWrite
          createdAt
          updatedAt
          closedAt
        }
      }
    }
  }
`;

const withQuery =
  (WrappedComponent) =>
  ({ ...rest }) => {
    const { loading, error, data } = useQuery(RISK_ENTITIES_QUERY, {});
    if (error)
      return (
        <div>
          <p>[{error.toString()}]</p>
          <Link href="entities">Try again</Link>
        </div>
      );

    let entities = [];
    if (data) {
      entities = mapConnection(data.riskEvents);
      entities = entities.map((e) => remapEntity(e));
    }

    return (
      <WrappedComponent
        entities={entities}
        currentUser={store.getState().users.currentUser}
        loading={loading}
        {...rest}
      />
    );
  };

export default withQuery;
