export const SAVE_TOKEN = 'SAVE_TOKEN';
export const CLEAR_TOKEN = 'CLEAR_TOKEN';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_ALL_USERS = 'SET_ALL_USERS';
export const SET_DICTIONARY = 'SET_DICTIONARY';
export const GET_DICTIONARY = 'GET_DICTIONARY';

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

export const SET_RISK_COLUMNS = 'SET_RISK_COLUMNS';

export const HIDE_ERROR_POPUP = 'HIDE_ERROR_POPUP';
export const SET_GRAPHQL_ERRORS = 'SET_GRAPHQL_ERRORS';
export const SET_NETWORK_ERROR = 'SET_NETWORK_ERROR';

export const UPDATE_SERVICE_WORKER = 'UPDATE_SERVICE_WORKER';

export const SET_RISK_FILTERS = 'SET_RISK_FILTERS';
