import { Grid } from '@material-ui/core';
import React, { PropsWithChildren, useState } from 'react';
import { IWithMessages } from './withQuery';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import moment from 'moment';
import { IMessage } from './types';
import SetReadMessageMutation from '../../mutations/SetReadMessageMutation';
import { useMutation } from '@apollo/client';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    details: {
      alignItems: 'left',
    },
    column: {
      flexBasis: '20%',
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    hasBeenRead: {
      backgroundColor: 'lightgray',
    },
  })
);

function sleep(time: any) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

const MessageTable: React.FC<PropsWithChildren<IWithMessages>> = (props) => {
  const classes = useStyles();
  const [setReadMessageMut] = useMutation(SetReadMessageMutation);

  const [rows, setRows] = useState<IMessage[]>(props.rows);

  async function handleReadMessage(messageId: number) {
    let rowId = rows.findIndex((r) => r.id === messageId);

    try {
      const result = await setReadMessageMut({
        variables: {
          input: { messageId: messageId },
        },
      });

      if (
        result.data.messagesSetRead != null &&
        result.data.messagesSetRead.errors != null &&
        result.data.messagesSetRead.errors.length > 0
      ) {
        console.log(result.data.messagesSetRead.errors[0].message);
      } else {
        const myRows = rows.slice();
        if (rowId > -1) {
          myRows[rowId] = result.data.messagesSetRead.message;

          sleep(10000).then(() => {
            setRows(myRows);
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        Messages
      </Grid>
      <Grid item xs={12}>
        {rows.map((msg, index) => {
          return (
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              className={msg.hasBeenRead ? classes.hasBeenRead : ''}
              onClick={() => handleReadMessage(msg.id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div className={classes.column}>
                  <Typography className={classes.heading}>
                    {moment(msg.createdAt).format('yyyy-MM-DD / HH:mm')}
                  </Typography>
                </div>
                <div className={classes.column}>
                  <Typography className={classes.secondaryHeading}>
                    {msg.title}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <div className={classes.column} />
                <div className={classes.column}>{msg.content}</div>
                <div className={clsx(classes.column, classes.helper)}>
                  {msg.issuer ? <Chip label={msg.issuer.name} /> : ''}
                </div>
              </AccordionDetails>
              <Divider />
              <AccordionActions>
                <Button size="small" color="primary">
                  Delete
                </Button>
              </AccordionActions>
            </Accordion>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default MessageTable;
