const connectionMapper = (connection) => {
  if (!connection) {
    return undefined;
  }
  return connection.edges.map((edge) => {
    return edge.node;
  });
};

export default connectionMapper;
