import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import Spinner from '../components/UI/Spinner';
import gql from 'graphql-tag';
import { setDictionary } from '../actions/dictionary-actions';
import { setRiskColumns } from '../actions/risk-columns-actions';
import { setAllUsers, setCurrentUser } from '../actions/users-actions';
import store from '../store/store';
import mapConnection from '../utils/connectionMapper';
import dictionaryMapper from '../utils/dictionaryMapper';

const fetchData = ({ riskColumns, dictionaries, users, currentUser }) => {
  store.dispatch(setAllUsers(mapConnection(users)));
  store.dispatch(setRiskColumns(riskColumns));
  store.dispatch(setCurrentUser(currentUser));

  const dicts = mapConnection(dictionaries);
  [
    'Dictionaries::Category',
    'Dictionaries::Subcategory',
    'Dictionaries::Strategy',
    'Dictionaries::Status',
    'Dictionaries::Phase',
    'Dictionaries::Range',
    'Dictionaries::Globality',
  ].forEach((type) => {
    store.dispatch(setDictionary(type, dictionaryMapper(dicts, type)));
  });

  [
    'Dictionaries::Overcost',
    'Dictionaries::Overtime',
    'Dictionaries::Impact',
    'Dictionaries::Probability',
    'Dictionaries::Priority',
  ].forEach((type) => {
    store.dispatch(setDictionary(type, dictionaryMapper(dicts, type, 'level')));
  });
};

const GET_THE_RISK_COLUMNS = gql`
  query RiskColumns {
    riskColumns {
      id
      columnType
      key
      name
      format
      width
      sortable
      resizable
      notMovable
      visible
      order
    }
  }
`;

const GET_THE_DICTIONARIES = gql`
  query Dictionaries {
    dictionaries(orderBy: [["label", "ASC"]]) {
      edges {
        node {
          type
          typeName
          label
          id
          active
          contextName
          level
          description
        }
      }
    }
  }
`;

const GET_THE_USERS = gql`
  query Users {
    users(orderBy: [["first_name", "ASC"], ["last_name", "ASC"]]) {
      edges {
        node {
          id
          name
          myGroups {
            name
          }
        }
      }
    }
  }
`;

const GET_THE_CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      email
      firstName
      lastName
      name
      phone
      position
      abbreviation
      office
      leaderGroups
      riskGroup
      createdAt
      avatarUrl
      myGroups {
        id
        name
        users {
          id
          name
        }
      }
    }
  }
`;

const DataLoaderContext = React.createContext({});

const DataLoaderProvider = ({ children }) => {
  const columnsData = useQuery(GET_THE_RISK_COLUMNS);
  const usersData = useQuery(GET_THE_USERS);
  const dictsData = useQuery(GET_THE_DICTIONARIES);
  const currentUserData = useQuery(GET_THE_CURRENT_USER);

  useEffect(() => {
    if (
      !columnsData.loading &&
      !usersData.loading &&
      !dictsData.loading &&
      !currentUserData.loading
    ) {
      fetchData({
        riskColumns: columnsData.data.riskColumns,
        dictionaries: dictsData.data.dictionaries,
        users: usersData.data.users,
        currentUser: currentUserData.data.currentUser,
      });
    }
  }, [columnsData, usersData, dictsData, currentUserData]);

  return (
    <DataLoaderContext.Provider value={{}}>
      {columnsData.loading ||
      usersData.loading ||
      dictsData.loading ||
      currentUserData.loading ? (
        <Spinner />
      ) : (
        children
      )}
    </DataLoaderContext.Provider>
  );
};

export { DataLoaderProvider, DataLoaderContext };
