import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import mapConnection from '../../../utils/connectionMapper';
import remapEntity from '../../../utils/remapRiskTicketFields';

const GET_HISTORY_QUERY = gql`
  query GetRiskEventHistory($filter: RiskEventHistoryFilterInputObject!) {
    riskEventHistory(filter: $filter) {
      edges {
        node {
          identifier
          specification
          migrationScenario
          title
          owner {
            name
          }
          createdBy {
            name
          }
          updatedBy {
            name
          }

          createdAt
          updatedAt

          category {
            label
          }
          subcategory {
            label
          }
          strategy {
            label
          }
          phase {
            label
          }
          status {
            label
          }
          priority {
            label
          }
          impact {
            label
          }
          globality {
            label
          }
          range {
            label
          }
          reportingCycle {
            id
            title
          }
          riskGroup {
            name
          }
          probability
          overtime
          overcost

          canWrite
        }
      }
    }
  }
`;

function withQuery(WrappedComponent) {
  return (props) => {
    const { loading, error, data } = useQuery(GET_HISTORY_QUERY, {
      variables: {
        filter: {
          riskEventId: props.riskEntityId,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (error) return <p>ERROR</p>;

    const rows = data
      ? mapConnection(data.riskEventHistory).map((e) => remapEntity(e))
      : [];

    return <WrappedComponent {...props} rows={rows} loading={loading} />;
  };
}

export default withQuery;
