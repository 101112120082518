import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { DataLoaderProvider } from '../../contexts/DataLoaderContext';

import useAuth from '../../hooks/useAuth';

interface AuthGuardType {
  children: React.ReactNode;
}

function AuthGuard({ children }: AuthGuardType) {
  const { isAuthenticated, isInitialized } = useAuth();

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  }

  return (
    <React.Fragment>
      <DataLoaderProvider>{children}</DataLoaderProvider>
    </React.Fragment>
  );
}

export default AuthGuard;
