import React from 'react';
import { useQuery, gql } from '@apollo/client';
import Spinner from '../UI/Spinner';
import {
  DEFAULT_GLOBALITY_FILTER,
  DEFAULT_RANGE_FILTER,
  DEFAULT_STATUS_FILTER,
  DEFAULT_PRIORITY_FILTER,
} from '../../utils/consts';

import { Card, Table } from 'react-bootstrap';
import store from '../../store/store';
import priorityColor from '../../utils/priorityColor';

const STATS_QUERY = gql`
  query statistics(
    $reportName: String
    $filter: [RiskStatsFilterInputObject!]
  ) {
    priorityReport(reportName: $reportName, filter: $filter) {
      probabilityId
      impactId
      value
      ids
      priorityId
    }
    currentReport {
      title
    }
  }
`;

const ImpactProbStats = (props) => {
  const { stats } = props;
  const probabilityDictionary = store
    .getState()
    .dictionary['Dictionaries::Probability'].sort((a, b) => a.level - b.level);
  const impactDictionary = store
    .getState()
    .dictionary['Dictionaries::Impact'].sort((a, b) => a.level - b.level)
    .reverse();
  const getStatsValue = (probabilityId, impactId) => {
    const stat = stats.find(
      (st) => st.probabilityId === probabilityId && st.impactId === impactId
    );
    return stat ? stat.value : '0';
  };

  const getStatsStyleClass = (probabilityId, impactId) => {
    const stat = stats.find(
      (st) => st.probabilityId === probabilityId && st.impactId === impactId
    );

    if (!stat) {
      return ['', ''];
    } else {
      return priorityColor(stat.priorityId);
    }
  };

  return (
    <Table bordered>
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th colSpan={5} className="text-center">
            <Card.Title>
              <strong>Likelihood</strong>
            </Card.Title>
          </th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          {probabilityDictionary.map((dict) => {
            return (
              <th style={{ width: '18%' }} className="text-center align-middle">
                {dict.description}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {impactDictionary.map((imp) => {
          return (
            <tr>
              {imp.level === impactDictionary.length && (
                <th
                  rowSpan={5}
                  style={{
                    verticalAlign: 'middle',
                    textOrientation: 'upright',
                  }}
                >
                  <strong>Impact</strong>
                </th>
              )}
              <th style={{ textAlign: 'right' }}>{imp.value}</th>
              {probabilityDictionary.map((prob) => {
                const colors = getStatsStyleClass(prob.id, imp.id);
                return (
                  <td
                    bg="primary"
                    style={{ width: '18%', textAlign: 'center' }}
                    className={colors[0]}
                  >
                    <strong className={colors[1]}>
                      {getStatsValue(prob.id, imp.id)}
                    </strong>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const withQuery = (WrappedComponent) => (props) => {
  const { loading, data } = useQuery(STATS_QUERY, {
    variables: {
      reportName: props.reportName,
      filter: [
        {
          name: 'status',
          includeValues: props.filters
            ? props.filters.status
            : DEFAULT_STATUS_FILTER,
        },
        {
          name: 'globality',
          includeValues: props.filters
            ? props.filters.globality
            : DEFAULT_GLOBALITY_FILTER,
        },
        {
          name: 'range',
          includeValues: props.filters
            ? props.filters.range
            : DEFAULT_RANGE_FILTER,
        },
        {
          name: 'priority',
          includeValues: props.filters
            ? props.filters.priority
            : DEFAULT_PRIORITY_FILTER,
        },
      ],
    },
  });
  const stats = data ? data.priorityReport : [];
  const reportName = data ? data.currentReport.title : [];
  return (
    <WrappedComponent
      {...props}
      stats={stats}
      loading={loading}
      reportName={props.reportName || reportName}
    />
  );
};

const PrioritiesTable = (props) => {
  const { loading, stats, classes } = props;
  return (
    <Card className="p-2 flex-fill">
      <Card.Header>
        <Card.Title className="mb-0">Report: {props.reportName}</Card.Title>
      </Card.Header>
      {loading ? (
        <Spinner />
      ) : (
        stats && (
          <ImpactProbStats
            stats={stats}
            classes={classes}
            reportName={props.reportName}
          />
        )
      )}
    </Card>
  );
};

export default withQuery(PrioritiesTable);
