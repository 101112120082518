import React, { useContext } from 'react';
import { SortableHeaderCell } from 'react-data-grid';
import { useFocusRef } from '../useFocusRef';
import FilterContext from './FilterContext';

const FilterRenderer = ({
  sortDirection,
  priority,
  onSort,
  isCellSelected,
  column,
  children,
}) => {
  const filters = useContext(FilterContext);
  const { ref, tabIndex } = useFocusRef(isCellSelected);

  return (
    <>
      {column.sortable ? (
        <SortableHeaderCell
          onSort={onSort}
          sortDirection={sortDirection}
          priority={priority}
          isCellSelected={isCellSelected}
        >
          {column.name}
        </SortableHeaderCell>
      ) : (
        <div style={{ paddingLeft: 5 }}>{column.name}</div>
      )}
      <div style={{ paddingLeft: 5 }}>
        {children({ ref, tabIndex, filters })}
      </div>
    </>
  );
};

export default FilterRenderer;
