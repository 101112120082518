import { combineReducers } from 'redux';

import token from './token-reducer';
import message from './message-reducer';
import error from './error-reducer';
import misc from './miscReducer';
import users from './users-reducer';
import dictionary from './dictionary-reducer';
import riskFilters from './riskFilters-reducer';
import riskColumns from './riskColumns-reducer';

export default combineReducers({
  token,
  message,
  error,
  misc,
  users,
  dictionary,
  riskFilters,
  riskColumns,
});
