import gql from 'graphql-tag';

const UsersChangePasswordMutation = gql`
  mutation UsersChangePassword($input: ChangePasswordInput!) {
    usersChangePassword(input: $input) {
      user {
        id
        email
        firstName
        lastName
        name
        phone
        position
        abbreviation
        office
        leaderGroups
        riskGroup
        avatarUrl
        createdAt
      }
      errors {
        name
        message
      }
    }
  }
`;

export default UsersChangePasswordMutation;
