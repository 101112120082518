import React, { Component, useContext } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  withStyles,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import UsersDisableMutation from '../../mutations/UsersDisableMutation';
import { useMutation } from '@apollo/client';
import NotyfContext from '../../contexts/NotyfContext';
import { displayErrorMessage } from '../../utils/displayMessage';

class DeleteDialog extends Component {
  handleDelete = async () => {
    const { deleteMutHandler, handleDialogClose, user, notyf } = this.props;

    try {
      let result = await deleteMutHandler({
        variables: {
          input: { userId: user ? user.id : null },
        },
      });

      if (
        result != null &&
        result.data.adminUsersDisable != null &&
        result.data.adminUsersDisable.errors != null &&
        result.data.adminUsersDisable.errors.length > 0
      ) {
        displayErrorMessage(
          notyf,
          result.data.adminUsersDisable.errors[0].message
        );
      } else {
        handleDialogClose(result.data.adminUsersDisable.user);
      }
    } catch (err) {
      console.error(err);
      displayErrorMessage(notyf, err.message);
    }
  };

  render() {
    const { dialogOpen, handleDialogClose, user } = this.props;

    return (
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Disable user</DialogTitle>
        <DialogContent>
          <Typography>
            Do you really want to disable user: {user ? user.email : ''} ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => handleDialogClose()}
            color="secondary"
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => this.handleDelete()}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
const styles = (theme) => ({
  formControl: {
    width: '140px',
  },
});

const DeleteDialogWrapper = (props) => {
  const [deleteMutation] = useMutation(UsersDisableMutation);
  const notyf = useContext(NotyfContext);

  return (
    <DeleteDialog {...props} notyf={notyf} deleteMutHandler={deleteMutation} />
  );
};

export default withStyles(styles)(DeleteDialogWrapper);
