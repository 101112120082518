import React, { Component, useContext } from 'react';
import {
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  withStyles,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import UsersCreateMutation from '../../mutations/UsersCreateMutation';
import { useMutation } from '@apollo/client';
import NotyfContext from '../../contexts/NotyfContext';
import { displayErrorMessage } from '../../utils/displayMessage';

class AddDialog extends Component {
  handleAdd = async () => {
    const { createMutHandler, handleDialogClose, notyf } = this.props;

    try {
      let result = await createMutHandler({
        variables: {
          input: {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            position: this.state.position,
            abbreviation: this.state.abbreviation,
            phone: this.state.phone,
            office: this.state.office,
            role: this.state.role,
            riskGroupId: this.state.riskGroup?.id
              ? parseInt(this.state.riskGroup.id)
              : 0,
          },
        },
      });

      console.log(result);

      if (
        result != null &&
        result.data.adminUsersCreate != null &&
        result.data.adminUsersCreate.errors != null &&
        result.data.adminUsersCreate.errors.length > 0
      ) {
        displayErrorMessage(
          notyf,
          result.data.adminUsersCreate.errors[0].message
        );
      } else {
        handleDialogClose(result.data.adminUsersCreate.user);
      }
    } catch (err) {
      console.error(err);
      this.setState({ ...this.state, errorMessage: err.message });
      displayErrorMessage(notyf, err.message);
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      position: '',
      abbreviation: '',
      phone: '',
      office: '',
      role: 'member',
      riskGroup: null,
    };
  }

  render() {
    const { dialogOpen, handleDialogClose, classes, groups } = this.props;

    return (
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Create new user</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="standard-multiline-flexible"
                  label="First name"
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      firstName: event.target.value,
                    });
                  }}
                  style={{ width: 500 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="standard-multiline-flexible"
                  label="Last name"
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      lastName: event.target.value,
                    });
                  }}
                  style={{ width: 500 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="standard-multiline-flexible"
                  label="Email"
                  onChange={(event) => {
                    this.setState({ ...this.state, email: event.target.value });
                  }}
                  style={{ width: 500 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="standard-multiline-flexible"
                  label="Position"
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      position: event.target.value,
                    });
                  }}
                  style={{ width: 500 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="standard-multiline-flexible"
                  label="Abbreviation"
                  multiline
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      abbreviation: event.target.value,
                    });
                  }}
                  style={{ width: 500 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="standard-multiline-flexible"
                  label="Phone"
                  onChange={(event) => {
                    this.setState({ ...this.state, phone: event.target.value });
                  }}
                  style={{ width: 500 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="standard-multiline-flexible"
                  label="Office"
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      office: event.target.value,
                    });
                  }}
                  style={{ width: 500 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Risk group
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(event) =>
                    this.setState({
                      ...this.state,
                      riskGroup: groups.find(
                        (g) => g.name === event.target.value
                      ),
                    })
                  }
                >
                  {groups &&
                    groups.map((group) => (
                      <MenuItem key={group.id} value={group.name}>
                        {group.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Role</FormLabel>
                <RadioGroup
                  row
                  aria-label="position"
                  name="role"
                  value={this.state.role}
                >
                  <FormControlLabel
                    value="member"
                    control={<Radio color="primary" />}
                    label="Member"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="admin"
                    control={<Radio color="primary" />}
                    label="Admin"
                    labelPlacement="start"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleDialogClose(null)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} align="right">
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.handleAdd()}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}
const styles = (theme) => ({
  formControl: {
    width: '300px',
  },
});

const AddDialogWrapper = (props) => {
  const [createMutation] = useMutation(UsersCreateMutation);
  const notyf = useContext(NotyfContext);

  return (
    <AddDialog {...props} notyf={notyf} createMutHandler={createMutation} />
  );
};

export default withStyles(styles)(AddDialogWrapper);
