export const DEFAULT_PHASE_FILTER = ['phase_production', 'phase_test'];
export const DEFAULT_STATUS_FILTER = [
  'status_new',
  'status_closed',
  'status_residual',
  'status_active',
];
export const DEFAULT_STATUS_FILTER_NO_CLOSED = [
  'status_new',
  'status_residual',
  'status_active',
];
export const DEFAULT_GLOBALITY_FILTER = ['globality_spc', 'globality_glb'];
export const DEFAULT_RANGE_FILTER = ['range_sub', 'range_etf'];
export const DEFAULT_PRIORITY_FILTER = [
  'priority_l',
  'priority_m',
  'priority_h',
  'priority_vh',
];
