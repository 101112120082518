import React from 'react';
import { useQuery } from '@apollo/client';
import mapConnection from '../../utils/connectionMapper';
import gql from 'graphql-tag';

const DICTIONARIES_QUERY = gql`
  query dictioneries {
    dictionaries {
      edges {
        node {
          id
          typeName
          type
          label
          level
          active
          description
        }
      }
    }
  }
`;

const withQuery = (WrappedComponent) => (props) => {
  const { loading, data } = useQuery(DICTIONARIES_QUERY);
  const dicts = data ? mapConnection(data.dictionaries) : [];
  return <WrappedComponent {...props} dictionary={dicts} loading={loading} />;
};

export default withQuery;
