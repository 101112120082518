import { UPDATE_SERVICE_WORKER } from '../actions/action-types';

export const initialState = {
  hasUpdate: false,
};

const miscReducer = (state = initialState, action = {}) => {
  const { type } = action;

  switch (type) {
    case UPDATE_SERVICE_WORKER:
      return {
        hasUpdate: true,
      };

    default:
      return state;
  }
};

export default miscReducer;
