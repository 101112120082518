import React, { Component } from 'react';
import { DialogContent, DialogTitle } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CreateRiskEntityMutation from '../../mutations/CreateRiskEntityMutation';
import remapEntity from '../../utils/remapRiskTicketFields';
import Grid from '@material-ui/core/Grid';

class DeleteDialog extends Component {
  handleAdd = async () => {
    const { client, handleDialogClose } = this.props;

    this.setState({ loading: true });

    try {
      let result = await client.mutate({
        mutation: CreateRiskEntityMutation,
        variables: {
          input: {
            riskGroup: this.state.group,
            specification: this.state.specification,
            phase: this.state.phase,
            owner: this.state.owner,
          },
        },
      });

      if (result.errors != null && result.errors.length > 0) {
        this.setState({
          ...this.state,
          errorMessage: result.errors[0].message,
        });
      } else if (
        result != null &&
        result.data.riskEntitiesCreate != null &&
        result.data.riskEntitiesCreate.errors != null &&
        result.data.riskEntitiesCreate.errors.length > 0
      ) {
        this.setState({
          ...this.state,
          errorMessage: result.data.riskEntitiesCreate.errors[0].message,
        });
      } else {
        handleDialogClose(
          remapEntity(result.data.riskEntitiesCreate.riskEntity)
        );
      }
    } catch (err) {
      console.error(err);
      this.setState({ ...this.state, errorMessage: err.message });
    }

    this.setState({
      loading: false,
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      name: null,
      abbreviation: null,
      leader: null,
      parentGroup: null,
      number: null,
      default_range: null,
      default_globality: null,
      errorMessage: null,
    };
  }

  render() {
    const { open, handleDialogClose } = this.props;

    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={open}
          onClose={handleDialogClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            Delete risk group
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}></Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}
/*
const styles = (theme) => ({
  formControl: {
    width: '140px',
  },
});
*/
// export default withStyles(styles)(DeleteDialog);
export default DeleteDialog;
