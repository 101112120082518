import React from 'react';
import Spinner from '../UI/Spinner';
import DictionariesTable from './DictionariesTable';
import withQuery from './withQuery';

const DictionariesPage = (props: any) => {
  const { loading, dictionary } = props;
  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        dictionary && <DictionariesTable dictionary={dictionary} />
      )}
    </div>
  );
};

export default withQuery(DictionariesPage);
