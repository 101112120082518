import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Settings } from 'react-feather';

import avatar1 from '../../assets/img/avatars/avatar.jpg';
import useAuth from '../../hooks/useAuth';
import store from '../../store/store';

const NavbarUser = () => {
  const { signOut } = useAuth();

  const user = store.getState().users.currentUser;

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <img
            src={user && user.avatarUrl ? user.avatarUrl : avatar1}
            className="avatar img-fluid rounded-circle me-1"
            alt={user && user.name}
          />
          <span className="text-dark">{user && user.name}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu>
        <Dropdown.Item>Settings & Privacy</Dropdown.Item>
        <Dropdown.Item>Help</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => signOut()}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
